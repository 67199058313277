import { DownloadOutlined } from "@ant-design/icons";
import { Grid, Typography } from "@mui/material";
import { Alert } from "antd";
import FilePondPluginFileValidateSize from "filepond-plugin-file-validate-size";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import "filepond/dist/filepond.min.css";
import Marquee from "react-fast-marquee";
import { FilePond, registerPlugin } from "react-filepond";
import { getBase64 } from "src/utils/getbase64.js";
import * as api from "../../../api/index.js";
import "../../ClientAccount/UploadDocs.scss";
export default function UploadDocs({ sendDataToParent, postData, category }) {
    const files = [];
    registerPlugin(
        FilePondPluginFileValidateType,
        FilePondPluginFileValidateSize,
        FilePondPluginImageExifOrientation,
        FilePondPluginImagePreview
    );
    let counter = 0;
    const updateFileUpload = (fileItems, docLabel, type) => {
        counter++;
        let myFile = fileItems[0]["file"];
        if (counter === 1) {
            getBase64(myFile)
                .then((data) => {
                    if (type.length === 2) {
                        postData.fileType = "excel";
                        postData.excelFile = data;
                    } else {
                        postData.fileType = "notExcel";
                    }
                    postData.fileName = myFile.name;
                    postData.uploadFile = true;
                    postData.selectedFile = data;
                    let findUploadedItem = postData.missingDocs.findIndex(
                        (x) => x === docLabel
                    );
                    if (findUploadedItem > -1) {
                        postData.missingDocs.splice(findUploadedItem, 1);
                    }
                    if (postData.missingDocs.length === 0) {
                        postData.enableFinish = true;
                        sendDataToParent(postData);
                    }
                })
                .then(() => fetchDataAction());
        }
    };
    const fetchDataAction = async () => {
        let updatePost = await api.updatePost(
            localStorage.getItem("newFilePostID"),
            {
                ...postData,
                name: postData.clientName,
            }
        );
        counter = 0;
    };
    console.log(category.docsUpload);
    return (
        <form>
            <Grid container spacing={2}>
                <Alert
                    type="info"
                    showIcon
                    banner
                    message={
                        <Marquee pauseOnHover gradient={false}>
                            Note : Merge documents to one file to upload incase
                            of multiple documents in any category
                        </Marquee>
                    }
                />
                <Alert
                    style={{ margin: "1em", borderRadius: "10px" }}
                    icon={<DownloadOutlined />}
                    message={
                        <a href="https://res.cloudinary.com/asset-aivc/raw/upload/v1663962491/Client_Details_Excel_Sheet_lf1oe8.xlsx">
                            Click to download client excel sheet template and
                            fill the details
                        </a>
                    }
                    type="info"
                    showIcon
                />
                {category.docsUpload.map((document, index) => {
                    if (document.isMandatory) {
                        postData.missingDocs.push(document.label);
                    }
                    return (
                        <Grid item xs={12} key={index} className="visitGrid">
                            <Grid item xs={6}>
                                <label
                                    className={
                                        document.isMandatory ? "mandate" : ""
                                    }
                                >
                                    {document.label}
                                </label>
                                <h5>{document.supportFile}</h5>
                            </Grid>
                            <Grid item xs={6}>
                                <FilePond
                                    allowFileTypeValidation={true}
                                    acceptedFileTypes={
                                        document.acceptedFileType
                                    }
                                    files={files}
                                    allowFileSizeValidation={true}
                                    maxFileSize="4MB"
                                    dropValidation={true}
                                    labelMaxFileSizeExceeded={
                                        "File is too large"
                                    }
                                    onupdatefiles={(fileItems) => {
                                        if (
                                            fileItems.length &&
                                            fileItems[0].fileSize <= 4000000
                                        )
                                            updateFileUpload(
                                                fileItems,
                                                document.label,
                                                document.acceptedFileType
                                            );
                                    }}
                                    labelIdle='Drag & Drop your file or <span class="filepond--label-action">Browse</span>'
                                />
                            </Grid>
                        </Grid>
                    );
                })}
                {category.picsUpload.map((document, index) => {
                    if (document.isMandatory) {
                        postData.missingDocs.push(document.label);
                    }
                    return (
                        <Grid
                            item
                            xs={12}
                            key={index}
                            style={{ display: "flex" }}
                        >
                            <Grid item xs={6}>
                                <label
                                    className={
                                        document.isMandatory ? "mandate" : ""
                                    }
                                >
                                    {document.label}
                                </label>
                                <a
                                    href="https://www.canada.ca/en/immigration-refugees-citizenship/services/application/application-forms-guides/temporary-resident-visa-application-photograph-specifications.html"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <h5>{document.supportFile}</h5>
                                </a>
                            </Grid>
                            <Grid item xs={6}>
                                <FilePond
                                    allowFileTypeValidation={true}
                                    acceptedFileTypes={
                                        document.acceptedFileType
                                    }
                                    files={files}
                                    allowFileSizeValidation={true}
                                    maxFileSize="4MB"
                                    dropValidation={true}
                                    labelMaxFileSizeExceeded={
                                        "File is too large"
                                    }
                                    onupdatefiles={(fileItems) => {
                                        if (
                                            fileItems.length &&
                                            fileItems[0].fileSize <= 4000000
                                        )
                                            updateFileUpload(
                                                fileItems,
                                                document.label,
                                                document.acceptedFileType
                                            );
                                    }}
                                    labelIdle='Drag & Drop your file or <span class="filepond--label-action">Browse</span>'
                                />
                            </Grid>
                        </Grid>
                    );
                })}
            </Grid>
            {category?.sponsorDocs && (
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant="h5">
                            {category.checklist.Sponsor.title}:
                        </Typography>
                    </Grid>
                    {category.sponsorDocs.map((document, index) => {
                        if (document.isMandatory) {
                            postData.missingDocs.push(document.label);
                        }
                        return (
                            <Grid
                                item
                                xs={12}
                                key={index}
                                style={{ display: "flex" }}
                            >
                                <Grid item xs={6}>
                                    <label
                                        className={
                                            document.isMandatory
                                                ? "mandate"
                                                : ""
                                        }
                                    >
                                        {document.label}
                                    </label>
                                    <h5>{document.supportFile}</h5>
                                </Grid>
                                <Grid item xs={6}>
                                    <FilePond
                                        allowFileTypeValidation={true}
                                        acceptedFileTypes={
                                            document.acceptedFileType
                                        }
                                        files={files}
                                        allowFileSizeValidation={true}
                                        maxFileSize="4MB"
                                        dropValidation={true}
                                        labelMaxFileSizeExceeded={
                                            "File is too large"
                                        }
                                        onupdatefiles={(fileItems) => {
                                            if (
                                                fileItems.length &&
                                                fileItems[0].fileSize <= 4000000
                                            )
                                                updateFileUpload(
                                                    fileItems,
                                                    document.label,
                                                    document.acceptedFileType
                                                );
                                        }}
                                        labelIdle='Drag & Drop your file or <span class="filepond--label-action">Browse</span>'
                                    />
                                </Grid>
                            </Grid>
                        );
                    })}
                </Grid>
            )}
        </form>
    );
}
