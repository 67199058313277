import { Alert, Button, Card, Descriptions, notification, Tag } from "antd";
import { Content, Header } from "antd/lib/layout/layout";
import moment from "moment";
import { NavLink } from "react-router-dom";
import Navbar from "src/components/Header/Navbar";
import Page from "src/components/Page";
import HorizontalLinearStepper from "src/components/StepForm/HorizontalLinearStepper";

export const AppButton = ({ ...props }) => (
    <Button
        type={props.type}
        target={props.target}
        href={props.href}
        className={props.css}
        shape="round"
        disabled={props.disabled}
        size="large"
        block={props.block}
        onClick={props.click}
        htmlType={props.htmlType}
    >
        {props.title} {props.isCover && props.icon}
    </Button>
);
export const AppLink = ({ ...props }) => (
    <NavLink
        className={
            props.scrollNav && props.home
                ? "nav-menus"
                : !props.home
                ? "nav-menus"
                : "nav-menus navBlue"
        }
        to={props.to}
        onClick={props.click}
    >
        {props.title}
    </NavLink>
);
export const AppCard = ({ ...props }) => (
    <Card cover={props.cover} title={props.title} className={props.css}>
        {props.content}
    </Card>
);
export const forumDate = (DateDiff) => {
    let dateDifference = moment.duration(moment().diff(moment(DateDiff)))._data;
    if (dateDifference.years) {
        return <span>{dateDifference.years} years ago</span>;
    } else if (dateDifference.months) {
        return <span>{dateDifference.months} months ago</span>;
    } else if (dateDifference.days) {
        return <span>{dateDifference.days} days ago</span>;
    } else if (dateDifference.minutes) {
        return <span>{dateDifference.minutes} mins ago</span>;
    } else if (dateDifference.hours) {
        return <span>{dateDifference.hours} hours ago</span>;
    } else if (dateDifference.seconds) {
        return <span>{dateDifference.seconds} seconds ago</span>;
    }
};
export const cardContent = (category) => (
    <Page title={category.subtitle} className="home">
        <Navbar />
        <AppCard
            css="stepCard"
            content={
                <>
                    <h3>{category.description}</h3>
                    <HorizontalLinearStepper category={category} />
                </>
            }
        />
    </Page>
);
export const AppLayout = ({ ...props }) => (
    <>
        <Header
            style={{
                position: "fixed",
                zIndex: 1,
                width: "100%",
            }}
        >
            <Navbar fixedHeader />
        </Header>
        <Content style={props.css}>{props.content}</Content>
    </>
);

export const getETagColor = (status) => {
    switch (status) {
        case "Retained":
        case "Follow Up":
        case "Received Missing Documents":
            return "blue";
        case "File Submitted":
            return "green";
        default:
            return "orange";
    }
};
export const headerDetail = (applicant) => (
    <>
        <Descriptions title="Client Details" className="applicant-detail">
            <Descriptions.Item label=" Name">
                {applicant.clientName}
            </Descriptions.Item>
            <Descriptions.Item label=" Email">
                {applicant.email}
            </Descriptions.Item>
            <Descriptions.Item label=" Address">
                {applicant.retainerDetails.address}
            </Descriptions.Item>
            <Descriptions.Item label="Phone">
                {applicant.retainerDetails.phone}
            </Descriptions.Item>
        </Descriptions>
        <Descriptions
            title="File Status"
            style={{ borderTop: "1px solid #eee", paddingTop: "1em" }}
        >
            <Descriptions.Item label="File Category">
                {applicant.category}
            </Descriptions.Item>
            <Descriptions.Item label="Current Status">
                <Tag color={getETagColor(applicant.fileStatus)}>
                    {applicant.fileStatus}
                </Tag>
            </Descriptions.Item>
            <Descriptions.Item label="Created On">
                {new Date(applicant.createdAt).toDateString()}
            </Descriptions.Item>
            <Descriptions.Item label="Application Fess Payment Status">
                <Tag color="success">PAID</Tag>
            </Descriptions.Item>
            <Descriptions.Item label="Amount Paid">
                {applicant.payment.amount} CAD
            </Descriptions.Item>
            <Descriptions.Item label="Payment Date">
                {new Date(applicant.payment.paymentDate).toDateString()}
            </Descriptions.Item>
        </Descriptions>
    </>
);
export const successMsg = (message, description, css) => (
    <Alert
        style={css}
        message={message}
        description={description}
        type="success"
        showIcon
    />
);

export const openNotificationWithIcon = (type, placement) => {
    notification[type]({
        message: "Email Sent",
        description:
            "Thank You! Assignee has been notified successfully to follow up!",
        placement,
    });
};
