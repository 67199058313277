import { GlobalStyles as GlobalThemeStyles } from "@mui/material";
import { useTheme } from "@mui/material/styles";

export default function GlobalStyles() {
    const theme = useTheme();

    return (
        <GlobalThemeStyles
            styles={{
                "*": {
                    margin: 0,
                    padding: 0,
                    fontFamily: "Secular One, sans-serif",
                },
                html: {
                    width: "100%",
                    height: "100%",
                    WebkitOverflowScrolling: "touch",
                },
                body: {
                    width: "100%",
                    height: "100%",
                    fontSize: "14px",
                },
                "#root": {
                    width: "100%",
                    height: "100%",
                },
                input: {
                    "&[type=number]": {
                        MozAppearance: "textfield",
                        "&::-webkit-outer-spin-button": {
                            margin: 0,
                            WebkitAppearance: "none",
                        },
                        "&::-webkit-inner-spin-button": {
                            margin: 0,
                            WebkitAppearance: "none",
                        },
                    },
                },
                textarea: {
                    "&::-webkit-input-placeholder": {
                        color: theme.palette.text.disabled,
                    },
                    "&::-moz-placeholder": {
                        opacity: 1,
                        color: theme.palette.text.disabled,
                    },
                    "&:-ms-input-placeholder": {
                        color: theme.palette.text.disabled,
                    },
                    "&::placeholder": {
                        color: theme.palette.text.disabled,
                    },
                },

                img: { display: "block", maxWidth: "100%" },

                // Lazy Load Img
                ".blur-up": {
                    WebkitFilter: "blur(5px)",
                    filter: "blur(5px)",
                    transition: "filter 400ms, -webkit-filter 400ms",
                },
                ".blur-up.lazyloaded ": {
                    WebkitFilter: "blur(0)",
                    filter: "blur(0)",
                },
                ".ant-layout": {
                    backgroundColor: "#fff !important",
                },
                ".ant-layout-header": {
                    background: "#fff !important",
                    height: "75px !important",
                    padding: "0 1em !important",
                },
                ".ant-btn-primary": {
                    background: "#594ae3 !important",
                    borderColor: " #594ae3 !important",
                },
            }}
        />
    );
}
