import React from "react";

const NewVisitVisa = React.lazy(() =>
    import("./views/pages/StepsPages/NewVisitVisa")
);
const ExtendVisitVisa = React.lazy(() =>
    import("./views/pages/StepsPages/ExtendVisitVisa")
);
const NewStudyVisa = React.lazy(() =>
    import("./views/pages/StepsPages/NewStudyVisa")
);
const ExtendStudyVisa = React.lazy(() =>
    import("./views/pages/StepsPages/ExtendStudyVisa")
);
const PostGraduateVisa = React.lazy(() =>
    import("./views/pages/StepsPages/PostGraduateVisa")
);
const LMIAVisa = React.lazy(() => import("./views/pages/StepsPages/LMIAVisa"));
const SpousalVisa = React.lazy(() =>
    import("./views/pages/StepsPages/SpousalVisa")
);
const FamilyVisa = React.lazy(() =>
    import("./views/pages/StepsPages/FamilyVisa")
);
const ExtendWorkVisa = React.lazy(() =>
    import("./views/pages/StepsPages/ExtendWorkVisa")
);
const AdminDashboard = React.lazy(() => import("./components/Dashboard"));
const FollowUp = React.lazy(() =>
    import("./components/Dashboard/FollowUp/FollowUp")
);
const ClientDashboard = React.lazy(() =>
    import("./components/Dashboard/ClientDashboard")
);
export const AgentApplication = React.lazy(() =>
    import("./components/AgentAccount/AgentApplication")
);
const PendingDocuments = React.lazy(() =>
    import("./components/ClientAccount/MissingDocs")
);
const Family = React.lazy(() =>
    import("./components/Dashboard/Sponsor/Family")
);
const Spousal = React.lazy(() =>
    import("./components/Dashboard/Sponsor/Spousal")
);
const ExtendStudy = React.lazy(() =>
    import("./components/Dashboard/Study/ExtendStudy")
);
const NewStudy = React.lazy(() =>
    import("./components/Dashboard/Study/NewStudy")
);
const PGWP = React.lazy(() => import("./components/Dashboard/Study/NewStudy"));
const ExtendVisit = React.lazy(() =>
    import("./components/Dashboard/Visit/ExtendVisit")
);
const NewVisit = React.lazy(() =>
    import("./components/Dashboard/Visit/NewVisit")
);
const ExtendWork = React.lazy(() =>
    import("./components/Dashboard/Work/ExtendWork")
);
const NewWork = React.lazy(() => import("./components/Dashboard/Work/NewWork"));

export const routes = [
    {
        path: "new-visit-visa",
        component: <NewVisitVisa />,
    },
    {
        path: "extend-visit-visa",
        component: <ExtendVisitVisa />,
    },
    {
        path: "new-study-visa",
        component: <NewStudyVisa />,
    },
    {
        path: "extend-study-visa",
        component: <ExtendStudyVisa />,
    },
    {
        path: "work-visa",
        component: <PostGraduateVisa />,
    },
    {
        path: "lmia-work-visa",
        component: <LMIAVisa />,
    },
    {
        path: "family-visa",
        component: <FamilyVisa />,
    },
    {
        path: "spousal-visa",
        component: <SpousalVisa />,
    },
    {
        path: "extend-work-visa",
        component: <ExtendWorkVisa />,
    },
];
export const dashboardRoutes = [
    {
        path: "/dashboard/main",
        component: <AdminDashboard />,
    },
    {
        path: "/dashboard/NewVisit",
        component: <NewVisit />,
    },
    {
        path: "/dashboard/ExtendVisit",
        component: <ExtendVisit />,
    },
    {
        path: "/dashboard/NewStudy",
        component: <NewStudy />,
    },
    {
        path: "/dashboard/ExtendStudy",
        component: <ExtendStudy />,
    },
    {
        path: "/dashboard/PGWP",
        component: <PGWP />,
    },
    {
        path: "/dashboard/NewWork",
        component: <NewWork />,
    },
    {
        path: "/dashboard/ExtendWork",
        component: <ExtendWork />,
    },
    {
        path: "/dashboard/Family",
        component: <Family />,
    },
    {
        path: "/dashboard/Spousal",
        component: <Spousal />,
    },
    {
        path: "/dashboard/followUp",
        component: <FollowUp />,
    },
    {
        path: "/dashboard/client",
        component: <ClientDashboard />,
    },
    {
        path: "/dashboard/pending-documents",
        component: <PendingDocuments />,
    },
];
