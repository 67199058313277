import { Col, Collapse, Row } from "antd";
import parse from "html-react-parser";
import { AnimationOnScroll } from "react-animation-on-scroll";
import { Link } from "react-router-dom";
import { AppButton, AppLayout } from "src/common";
import { immigrationInfo } from "src/common/ImmigrationInfo";
import {
    ACTION_LINK,
    PAGE_SECTION,
    TITLE_LINK,
} from "src/constants/pageSection";
import Footer from "../Footer/FooterPageContent";
import Page from "../Page";
import "./Services.scss";
function Services() {
    const { Panel } = Collapse;
    return (
        <AppLayout
            css={{ marginTop: "3rem" }}
            content={
                <>
                    <Page title={TITLE_LINK.SERVICES}>
                        <AnimationOnScroll
                            animateOnce
                            animateIn="animate__fadeInUp"
                        >
                            <div className="top-service service-bg">
                                <h1 className="header-text textCenter header-service ">
                                    Our Services
                                </h1>
                                <Row gutter={8}>
                                    {PAGE_SECTION.SERVICES.serviceList.map(
                                        (item, index) => (
                                            <Col
                                                xs={24}
                                                sm={24}
                                                md={8}
                                                lg={8}
                                                xl={8}
                                                key={index}
                                            >
                                                <div className="subDescription serviceImg">
                                                    <span className="service-image">
                                                        <img
                                                            src={item.imgSrc}
                                                            alt=""
                                                        ></img>
                                                    </span>
                                                    <span className="subtext">
                                                        <span className="titleService">
                                                            {item.title}
                                                        </span>
                                                        <div
                                                            className="service-desc service-list"
                                                            style={{
                                                                color: " #ffffffcc",
                                                            }}
                                                        >
                                                            {parse(
                                                                item.description
                                                            )}
                                                        </div>
                                                    </span>
                                                </div>
                                            </Col>
                                        )
                                    )}
                                </Row>
                            </div>
                        </AnimationOnScroll>
                        <AnimationOnScroll
                            animateOnce
                            animateIn="animate__fadeInUp"
                        >
                            <div className="top-service home info-service">
                                <h5 className="highlight-stats textCenter">
                                    NEED MORE INFO
                                </h5>
                                <h1 className="header-text textCenter">
                                    How can you Immigrate to Canada?
                                </h1>

                                <Collapse className="service-collapse">
                                    {immigrationInfo.map((info) => (
                                        <Panel
                                            className="services-info"
                                            header={info.title}
                                            key={info.key}
                                        >
                                            {info.description}
                                        </Panel>
                                    ))}
                                </Collapse>
                                <Link to={ACTION_LINK.CONTACT.action}>
                                    <AppButton
                                        css="cover-btn"
                                        type="primary"
                                        title="
              Contact Us For More Info"
                                    />
                                </Link>
                            </div>
                        </AnimationOnScroll>
                    </Page>
                    <Footer />
                </>
            }
        />
    );
}

export default Services;
