import { OFFICE_AGENT, ROUTE_URL } from "src/common/values";
import {
    affidavitFileUploadTitle,
    clientInfoDoc,
    passportUploadDocsList,
    picFileUploadTitle,
    studyVisitUploadDocsList,
} from "./uploadDocuments/commonFilesToUpload";
import { PGWPDocsList } from "./uploadDocuments/pgwpUploadDocsList";
import {
    extendWorkDocsList,
    newSpousalDocsList,
    sponsorSpouseDocsList,
} from "./uploadDocuments/spouseUploadDocsList";
import {
    extendStudyDocsList,
    newStudyDocsList,
} from "./uploadDocuments/studyUploadDocsList";
import {
    extendVisitDocsList,
    familyDocsList,
    lmiaDocsList,
    newVisitDocsList,
    outsideVisaList,
    sponsorVisitDocsList,
} from "./uploadDocuments/visitUploadDocsList";

export const CREATE = "CREATE";
export const UPDATE = "UPDATE";
export const DELETE = "DELETE";
export const FETCH_ALL = "FETCH_ALL";
export const LIKE = "LIKE";
export const AUTH = "AUTH";
export const LOGOUT = "LOGOUT";
export const CREATE_NOTE = "CREATE_NOTE";
export const FETCH_ALL_NOTE = "FETCH_ALL_NOTE";
export const FETCH_COUNT = "FETCH_COUNT";
export const FETCH_CATEGORY_COUNT = "FETCH_CATEGORY_COUNT";

export const eCategory = {
    NewVisit: {
        subtitle: "New Visitor Visa Application",
        title: "New Visit",
        description: "Apply for New Visit Visa",
        fee: "$249.00",
        ROUTE_URL: "/" + ROUTE_URL.NewVisitVisa,
        checklist: {
            Applicant: {
                title: "Applicant",
                docs: [
                    "Passport Previous passport, if any",
                    "Recent Education Transcript",
                    "Work Experience like job letter, paystubs, etc.",
                    "Invitation",
                    "Digital photo",
                    "Family Information Proof of Funds – ITR (Parents or student)",
                    "Sponsorship Letters ",
                    "Travel History",
                    "Reason of Travel",
                    "Visa Copy",
                ],
            },
            Sponsor: {
                title: "Sponsor",
                docs: [
                    "Job Letter",
                    "Bank Account",
                    "Proof of Relation",
                    "Substantiate reason for travelling",
                    " Place of Accommodation",
                ],
            },
        },
        docsUpload: clientInfoDoc.concat(
            passportUploadDocsList,
            studyVisitUploadDocsList,
            newVisitDocsList,
            outsideVisaList
        ),
        picsUpload: picFileUploadTitle,
        sponsorDocs: sponsorVisitDocsList,
    },
    ExtendVisit: {
        subtitle: " Visitor Visa Extension",
        title: "Extend Visit",
        description: "Apply to Extend Visit Visa",
        fee: "$149.00",
        ROUTE_URL: "/" + ROUTE_URL.ExtendVisitVisa,
        checklist: {
            Applicant: {
                title: "Applicant",
                docs: [],
            },
            Sponsor: {
                title: "Sponsor",
                docs: [],
            },
        },
        docsUpload: clientInfoDoc.concat(
            passportUploadDocsList,
            extendVisitDocsList,
            newVisitDocsList,
            studyVisitUploadDocsList
        ),
        picsUpload: picFileUploadTitle,
        sponsorDocs: sponsorVisitDocsList,
    },
    NewStudy: {
        subtitle: "NEW STUDY PERMIT APPLICATION",
        title: "New Study",
        description: "Apply for New Study Visa",
        fee: "$99.00",
        ROUTE_URL: "/" + ROUTE_URL.NewStudyVisa,
        checklist: {
            Applicant: {
                title: "Applicant",
                docs: [],
            },
            Sponsor: {
                title: "If minor:",
                docs: [],
            },
        },
        docsUpload: clientInfoDoc.concat(
            passportUploadDocsList,
            studyVisitUploadDocsList,
            newStudyDocsList
        ),
        picsUpload: picFileUploadTitle,
        sponsorDocs: affidavitFileUploadTitle,
    },
    ExtendStudy: {
        subtitle: "Study Permit Extension",
        title: "Extend Study",
        description: "Apply to Extend Study Visa",
        fee: "$149.00",
        ROUTE_URL: "/" + ROUTE_URL.ExtendStudyVisa,
        checklist: {
            Applicant: {
                title: "Applicant",
                docs: [],
            },
            Sponsor: {
                title: "Sponsor",
                docs: [],
            },
        },
        docsUpload: clientInfoDoc.concat(
            passportUploadDocsList,
            extendStudyDocsList
        ),
        picsUpload: picFileUploadTitle,
    },
    ExtendWork: {
        subtitle: "Work Permit Extension",
        title: "Extend Work",
        description: "Apply to Extend Work Visa",
        fee: "$149.00",
        ROUTE_URL: "/" + ROUTE_URL.ExtendWorkVisa,
        checklist: {
            Applicant: {
                title: "Applicant",
                docs: [],
            },
            Sponsor: {
                title: "Sponsor",
                docs: [],
            },
        },
        docsUpload: clientInfoDoc.concat(
            passportUploadDocsList,
            extendWorkDocsList
        ),
        picsUpload: picFileUploadTitle,
    },
    Family: {
        subtitle: "Family Sponsorship Application",
        title: "Family",
        description: "Apply for Family Sponsorship",
        fee: "$749.00",
        ROUTE_URL: "/" + ROUTE_URL.FamilyVisa,
        checklist: {
            Applicant: {
                title: "Applicant",
                docs: [],
            },
            Sponsor: {
                title: "Sponsor",
                docs: [],
            },
        },
        docsUpload: clientInfoDoc.concat(
            passportUploadDocsList,
            familyDocsList
        ),
        picsUpload: picFileUploadTitle,
    },
    LMIA: {
        subtitle: "NEW WORK PERMIT APPLICATION",
        title: "LMIA",
        description: "Apply for New Work Visa",
        fee: "$199.00",
        ROUTE_URL: "/" + ROUTE_URL.LMIAVisa,
        checklist: {
            Applicant: {
                title: "Applicant",
                docs: [],
            },
            Sponsor: {
                title: "Sponsor",
                docs: [],
            },
        },
        docsUpload: clientInfoDoc.concat(passportUploadDocsList, lmiaDocsList),
        picsUpload: picFileUploadTitle,
    },
    PGWP: {
        subtitle: "POST GRADUATE WORK PERMIT",
        title: "PGWP",
        description: "Apply for New PGWP Visa",
        fee: "$199.00",
        ROUTE_URL: "/" + ROUTE_URL.PostGraduateVisa,
        checklist: {
            Applicant: {
                title: "Applicant",
                docs: [],
            },
            Sponsor: {
                title: "Sponsor",
                docs: [],
            },
        },
        docsUpload: clientInfoDoc.concat(passportUploadDocsList, PGWPDocsList),
        picsUpload: picFileUploadTitle,
    },

    Spousal: {
        subtitle: "SPOUSAL VISA APPLICATION",
        title: "Spousal",
        description: "Apply for  Spousal Visa",
        fee: "$499.00",
        ROUTE_URL: "/" + ROUTE_URL.SpousalVisa,
        checklist: {
            Applicant: {
                title: "Applicant",
                docs: [],
            },
            Sponsor: {
                title: "Sponsor",
                docs: [],
            },
        },
        docsUpload: clientInfoDoc.concat(
            passportUploadDocsList,
            newSpousalDocsList
        ),
        picsUpload: picFileUploadTitle,
        sponsorDocs: sponsorSpouseDocsList,
    },

    PNP: { title: "PNP", description: "Apply for New PNP Visa" },
    CEC: { title: "CEC", description: "Apply for New CEC Visa" },
    FSW: { title: "FSW", description: "Apply for New FSW Visa" },
    CEC_PNP: {
        title: "CEC+PNP",
        description: "Apply for New CEC and PNP Visa",
    },
    SpousalOpenWorkPermit: "Spousal Open Work Permit",
    TRV: "TRV",
    SuperVisa: "Super Visa",
};

export const PROGRAM_DETAILS = [
    {
        title: "New Visitor Visa Application",
        imgSrc: "/static/icons/visit1.png",
        key: "new_visit",
        description: `Do you want to :<ul><li>Stay in Canada as a visitor?</li><li>Travel on business?</li>
    <li>Reunite with your family members?</li><li>Travelling through Canada on transit?</li>
    <li>Invite your parents or relatives?</li></ul>`,
        fileType: {
            cost: eCategory.NewVisit.fee,
            routePath: eCategory.NewVisit.ROUTE_URL,
        },
    },
    {
        title: "Visitor Visa Extension",
        imgSrc: "/static/icons/extend_visit.png",
        key: "visit_extend",
        description: `Do you want to :<ul><li>Extend your stay in Canada?</li>
    <li>Extend your existing temporary resident visa status?</li><li>Know the requirements to extend your existing visa?</li></ul>`,
        fileType: {
            cost: eCategory.ExtendVisit.fee,
            routePath: eCategory.ExtendVisit.ROUTE_URL,
        },
    },
    {
        title: "Super Visa",
        imgSrc: "/static/icons/extend_visit.png",
        key: "visit_extend",
        description: `Do you want to :<ul><li>Extend your stay in Canada?</li>
    <li>Extend your existing temporary resident visa status?</li><li>Know the requirements to extend your existing visa?</li></ul>`,
        fileType: {
            cost: eCategory.ExtendVisit.fee,
            routePath: eCategory.ExtendVisit.ROUTE_URL,
        },
    },
    {
        title: "New Study Permit Application",
        imgSrc: "/static/icons/study.png",
        key: "new_study",
        description: `Do you want to :<ul><li>Stay in Canada as an international student?</li>
    <li>Apply for new study permit?</li><li>Know the life of a international student in Canada?</li>
    <li>Know how to apply for schools in Canada?</li><li>Know about study in Canada as a minor child?</li></ul>`,
        fileType: {
            cost: eCategory.NewStudy.fee,
            routePath: eCategory.NewStudy.ROUTE_URL,
        },
    },
    {
        title: "Study Permit Extension",
        imgSrc: "/static/icons/extend_study.png",
        key: "study_extend",
        description: `Do you want to :<ul><li>Extend your study permit?</li>
     <li>Continue to keep studying in Canada?</li><li>Know the requirements for study permit extension?</li><li>Restore your status as temporary student resident?</li>
   </ul>`,
        fileType: {
            cost: eCategory.ExtendStudy.fee,
            routePath: eCategory.ExtendStudy.ROUTE_URL,
        },
    },
    {
        title: "Post Graduate Work Permit ",
        imgSrc: "/static/icons/pgwp.png",
        key: "pgwp",
        description: `Do you want to :<ul><li>Work in Canada after graduation?</li>
    <li>Know the criteria to be eligible for PGWP?</li><li>Stay in Canada but no longer studying?</li>
    <li>Extend your post graduate work permit?</li></ul>`,
        fileType: {
            cost: eCategory.PGWP.fee,
            routePath: eCategory.PGWP.ROUTE_URL,
        },
    },
    // {
    //     title: "New Work Permit Application",
    //     imgSrc: "/static/icons/lmia.png",
    //     key: "new_work",
    //     description: `Do you want to :<ul><li>Work or find a job in Canada?</li>
    // <li>Apply for new work permit?</li><li>Know the different work permits for international workers?</li>
    // <li>Application for Labour Market International Assessment (LMIA)?</li></ul>`,
    //     fileType: {
    //         cost: eCategory.LMIA.fee,
    //         routePath: eCategory.LMIA.ROUTE_URL,
    //     },
    // },
    {
        title: "Work Permit Extension",
        imgSrc: "/static/icons/extend.png",
        key: "work_extend",
        description: `Do you want to :<ul><li>Continue working in Canada?</li>
    <li>Extend or change the condition on your work permit?</li><li>Apply for LMIA extension?</li>
    <li>Know eligibility for foreign workers in Canada?</li></ul>`,
        fileType: {
            cost: eCategory.ExtendWork.fee,
            routePath: eCategory.ExtendWork.ROUTE_URL,
        },
    },
    {
        title: " Spousal Open Work Permit Application",
        imgSrc: "/static/icons/spousal.png",
        key: "spousal",
        description: `Do you want to :<ul><li>Sponsor your spouse or common law partner to immigrate in Canada?</li><li>Extend your spousal visa application?</li>
    <li>Do you want to know the criteria to sponsor your spouse or partner ?</li>
   </ul>`,
        fileType: {
            cost: eCategory.Spousal.fee,
            routePath: eCategory.Spousal.ROUTE_URL,
        },
    },
];

export const PROFESSIONAL_SERVICE = [
    {
        title: "Visitor Visa Application",
        imgSrc: "/static/icons/visit.png",
        key: "visit",
        description: `<ul class="pro-service"><li>Visitor visa for workers and students</li>
    <li>Extend your stay in Canada</li><li>Invite business visitors</li> <li>Reunite with family members.</li></ul>`,
    },
    {
        title: " Study Permit Application",
        imgSrc: "/static/icons/study.png",
        key: "study",
        description: `<ul class="pro-service"><li>Get study permit to study in Canada</li>
    <li>Extend your study permit</li><li>Study in Canada as minor</li> <li>Restore your study permit status</li></ul>`,
    },
    {
        title: " Labour Market Impact Assessment (LMIA)",
        imgSrc: "/static/icons/lmia.png",
        key: "lmia",
        description: `<ul class="pro-service"><li>LMIA Assessment</li>
    <li>LMIA based Work Permit</li></ul>`,
    },
    {
        title: " Spousal Visa Application",
        imgSrc: "/static/icons/spousal.png",
        key: "spousal",
        description: `<ul class="pro-service"><li>Sponsor spouse/common law partner</li>
    <li>Assessment under Family Class</li> <li>Reunite with family.</li></ul>`,
    },
    {
        title: "Post Graduate Work Permit ",
        imgSrc: "/static/icons/pgwp.png",
        key: "pgwp",
        description: `<ul class="pro-service"><li>Work in Canada after you graduate
</li>
    <li>PGWP eligibility assessment</li><li>Stay in Canada after study</li> <li>Canadian work experience and lifestyle</li></ul>`,
    },
    {
        title: "Super Visa",
        imgSrc: "/static/icons/lmia.png",
        key: "lmia",
        description: `<ul class="pro-service"><li>LMIA Assessment</li>
    <li>LMIA based Work Permit</li></ul>`,
    },
];

export const eFileStatus = {
    Retained: "Retained",
    VerifyPending: "Files Pending for Verification",
    MissingDocsNotify: "Awaiting for Missing Documents",
    UploadedMissingDocs: "Received Missing Documents",
    FollowUp: "Follow Up",
    FileSubmitted: "File Submitted",
};
export const officeAssigneeList = ["Robin", "Bishan", "Monish", "Juli"];

export const uploadProps = {
    action: "https://www.mocky.io/v2/5cc8019d300000980a055e76",
    onChange({ file, fileList }) {
        if (file.status !== "uploading") {
            console.log(file, fileList);
        }
    },
    defaultFileList: [],
};

export function getFileAssignee(category) {
    let assignee = "";
    switch (category) {
        case eCategory.Spousal:
        case eCategory.CEC:
        case eCategory.PNP:
        case eCategory.FSW:
        case eCategory.CEC_PNP:
            assignee = OFFICE_AGENT.ASSISTANT.NAME;
            break;
        case eCategory.NewVisit:
        case eCategory.ExtendVisit:
        case eCategory.NewStudy:
        case eCategory.ExtendStudy:
        case eCategory.ExtendWork:
        case eCategory.PGWP:
            assignee = OFFICE_AGENT.SUPERVISOR.NAME;
            break;
        default:
            assignee = "";
    }
    return assignee;
}
export const visitPurposeList = [
    { text: "Business" },
    { text: "Tourism" },
    { text: "Study" },
    { text: "Family Visit" },
    { text: "Other" },
];

export const workPermitType = [
    { key: "open", value: "Open Work Permit" },
    { key: "lmia", value: "LMIA" },
];
