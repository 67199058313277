import { ArrowRightOutlined } from "@ant-design/icons";
import { Alert, Col, Row } from "antd";
import Meta from "antd/lib/card/Meta";
import parse from "html-react-parser";
import { useNavigate } from "react-router-dom";
import { AppButton, AppCard, AppLayout } from "src/common";
import { PROGRAM_DETAILS } from "src/constants/actionTypes";
import { ACTION_LINK, TITLE_LINK } from "src/constants/pageSection";
import FooterPageContent from "../Footer/FooterPageContent";
import Page from "../Page";
import "./Programs.scss";

function Programs() {
    const user = JSON.parse(localStorage.getItem("profile"));
    const setTitle = (item) => (
        <>
            <h1 className="pricing-cost">{item.fileType.cost}</h1>
            {parse(item.description)}
        </>
    );
    const history = useNavigate();
    return (
        <AppLayout
            css={{ marginTop: "5rem" }}
            content={
                <>
                    <Page title={TITLE_LINK.PROGRAMS} className="home">
                        <div className="textCenter">
                            <h1 className="program-cardTitle">
                                Choose Your Program
                            </h1>
                            <h3 className="info-desc">
                                Our price doesn't include GST and any
                                disbursements made on your behalf (Application
                                Fees, High Commission IRCC Fees, etc.). All
                                prices are FINAL and NON-REFUNDABLE and are
                                applicable for fresh applications ONLY. REFUSALs
                                will be charged ADDITIONAL amount.
                            </h3>
                        </div>
                        <Row gutter={16}>
                            {PROGRAM_DETAILS.map((item, index) => (
                                <Col
                                    key={index}
                                    xs={24}
                                    sm={24}
                                    md={12}
                                    lg={8}
                                    xl={8}
                                    className="program-card-margin"
                                >
                                    <AppCard
                                        css="program-card"
                                        cover={
                                            <img
                                                alt="example"
                                                className="programs-img"
                                                src={item.imgSrc}
                                            />
                                        }
                                        content={
                                            <>
                                                <Meta
                                                    className="programs-meta"
                                                    title={item.title}
                                                    description={setTitle(item)}
                                                />
                                                <AppButton
                                                    click={(e) => {
                                                        e.preventDefault();
                                                        if (user) {
                                                            history(
                                                                item.fileType
                                                                    .routePath
                                                            );
                                                        } else {
                                                            history("/login");
                                                        }
                                                    }}
                                                    css="program-action-btn"
                                                    block={true}
                                                    title={
                                                        ACTION_LINK.PROGRAMS
                                                            .title
                                                    }
                                                >
                                                    <ArrowRightOutlined />
                                                </AppButton>
                                            </>
                                        }
                                    />
                                </Col>
                            ))}
                        </Row>
                    </Page>
                    <FooterPageContent />
                </>
            }
        />
    );
}

export default Programs;
