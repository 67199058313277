import { Col, Row } from "antd";
import parse from "html-react-parser";
import { PROFESSIONAL_SERVICE } from "src/constants/actionTypes";
export default function ProfessionalServices() {
    return (
        <Row className="cover-row">
            <div>
                <h1 className="header-text textCenter">
                    Our Professional &nbsp;
                    <span className="highlightText">Services</span>
                </h1>
                <Row gutter={32} className="service-ro">
                    {PROFESSIONAL_SERVICE.map((service, index) => (
                        <Col xs={24} sm={24} md={12} lg={8} xl={8} key={index}>
                            <div className="subDescription prof-service">
                                <span className="service-image">
                                    <img src={service.imgSrc} alt=""></img>
                                </span>
                                <span className="subtext">
                                    <span className="service-title">
                                        {service.title}
                                    </span>
                                    <div
                                        className={
                                            service.key === "lmia"
                                                ? "service-desc service-list lmia-service"
                                                : "service-desc service-list"
                                        }
                                    >
                                        {parse(service.description)}
                                    </div>
                                </span>
                            </div>
                        </Col>
                    ))}
                </Row>
            </div>
        </Row>
    );
}
