export const getRoutePath = (email, userType) => {
    if (userType === USER_TYPE.AGENT) {
        switch (email) {
            case OFFICE_AGENT.ADMIN.EMAIL:
                return OFFICE_AGENT.ADMIN.ROUTE;
            default:
                return OFFICE_AGENT.SUPERVISOR.ROUTE;
        }
    } else {
        return USER_TYPE.APPLICANT_ROUTE;
    }
};
export const ROUTE_URL = {
    AboutUs: "about-us",
    Forum: "forum",
    Programs: "pricing",
    ResetPassword: "reset-password",
    ForgotPassword: "forgot-password",
    NewVisitVisa: "new-visit-visa",
    ExtendVisitVisa: "extend-visit-visa",
    NewStudyVisa: "new-study-visa",
    ExtendStudyVisa: "extend-study-visa",
    PostGraduateVisa: "work-visa",
    LMIAVisa: "lmia-work-visa",
    FamilyVisa: "family-visa",
    SpousalVisa: "spousal-visa",
    ExtendWorkVisa: "extend-work-visa",
    PNPVisa: "pnp-visa",
    CECVisa: "cec-visa",
    FSWVisa: "fsw-visa",
    CEC_PNP: "cec-pnp-visa",
};
export const DASHBOARD_ROUTE_PATH = {
    CLIENT: `/dashboard/client`,
    MAIN: `/dashboard/main`,
    VISIT: `/dashboard/NewVisit`,
    STUDY: `/dashboard/caseStudy`,
    WORK: `/dashboard/caseWork`,
    PR: `/dashboard/casePR`,
    FOLLOWUP: `/dashboard/followUp`,
};
export const USER_TYPE = {
    AGENT: "Agent",
    APPLICANT: "Applicant",
    APPLICANT_ROUTE: DASHBOARD_ROUTE_PATH.CLIENT,
};
export const OFFICE_AGENT = {
    ADMIN: {
        EMAIL: "robin@aivc.ca",
        NAME: "Robin",
        ROUTE: DASHBOARD_ROUTE_PATH.MAIN,
    },
    SUPERVISOR: {
        EMAIL: "bishan@aivc.ca",
        NAME: "Bishan",
        ROUTE: DASHBOARD_ROUTE_PATH.VISIT,
    },
    ASSISTANT: {
        EMAIL: "office@aivc.ca",
        NAME: "Monish",
        ROUTE: DASHBOARD_ROUTE_PATH.VISIT,
    },
    TECH: {
        EMAIL: "juli@aivc.ca",
        NAME: "Juli",
        ROUTE: DASHBOARD_ROUTE_PATH.VISIT,
    },
};
export const NAV_MENU = [
    { name: "About", value: ROUTE_URL.AboutUs },
    { name: "Services", value: "services" },
    { name: "Pricing", value: ROUTE_URL.Programs },
    { name: "Contact Us", value: "contact-us" },
    // { name: "Forum", value: ROUTE_URL.Forum },
];
export const SPECIALIZE_LIST = [
    {
        title: "Success Guaranteed",
        items: [
            { title: "Previous experience" },
            { title: "Familiar with immigration pathways" },
            {
                title: "Google 5 star rating",
            },
        ],
    },
    {
        title: "Experienced",
        items: [
            { title: "3+ years of experience" },
            { title: "Handled tough cases" },
            {
                title: "Delivered many approvals",
            },
        ],
    },
    {
        title: "ICCRC Licensed",
        items: [
            { title: "Regulated Consultant" },
            { title: "R523621" },
            {
                title: "Only deals in Canada Visa",
            },
        ],
    },
    {
        title: "Completely Custom",
        items: [
            { title: "Tailored to suit your needs" },
            { title: "Never use standard approach" },
            {
                title: "Specialize in Business Immigration - Owner Operator stream for PR",
            },
        ],
    },
];

export const FOOTER_ICON = [
    {
        class: "social-icon-link facebook",
        label: "Facebook",
        icon: "fab fa-facebook-f",
        url: "https://www.facebook.com/AllriseImmigration",
    },
    {
        class: "social-icon-link instagram",
        label: "Instagram",
        icon: "fab fa-instagram",
        url: "https://www.instagram.com/allriseimmigration/",
    },
    {
        class: "social-icon-link youtube",
        label: "Youtube",
        icon: "fab fa-youtube",
        url: "https://www.youtube.com/channel/UCSv0E7C-JAVGWT5xzxRUwgw/featured",
    },
    {
        class: "social-icon-link twitter",
        label: "Twitter",
        icon: "fab fa-twitter",
        url: "https://twitter.com/RobinKapoor88",
    },
    {
        class: "social-icon-link linkedin",
        label: "LinkedIn",
        icon: "fab fa-linkedin",
        url: "https://www.linkedin.com/in/robin-kapoor/",
    },
];
