import { Link } from "react-router-dom";
import { FOOTER_ICON, NAV_MENU } from "src/common/values";
import "./FooterPageContent.css";

function FooterPageContent() {
    return (
        <div className="footer-container">
            <div>
                <Link to="/" className="social-logo">
                    <img
                        src="/logos/extendthevisa-logos_white.png"
                        alt="logo"
                        className="img-logo img-logo-footer"
                    />
                </Link>
            </div>
            <div className="text-icon">
                {NAV_MENU.map((item, index) => (
                    <Link
                        to={`/${item.value}`}
                        key={index}
                        className="social-text-link"
                    >
                        {item.name}
                    </Link>
                ))}
            </div>
            <div className="social-icons">
                {FOOTER_ICON.map((item, index) => (
                    <a
                        key={index}
                        className={item.class}
                        aria-label={item.label}
                        rel="noReferrer"
                        target="_blank"
                        href={item.url}
                    >
                        <i className={item.icon} />
                    </a>
                ))}
            </div>
            <div className="social-footer">
                © Copyright 2022, @ExtendTheVisa | All Rights Reserved.
            </div>
        </div>
    );
}

export default FooterPageContent;
