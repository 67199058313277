export const newVisitDocsList = [
    {
        title: "visitWorkDoc",
        label: "Work Experience like job letter, paystubs, etc.",
        supportFile: "Supported File Type: *.pdf max 4MB",
        acceptedFileType: ["application/pdf"],
    },
    {
        title: "invitation",
        label: "Invitation",
        supportFile: "Supported File Type: *.pdf max 4MB",
        acceptedFileType: ["application/pdf"],
    },
];
export const familyDocsList = [
    {
        title: "parents",
        label: "Parents and Grand Parents Invitation to Apply",
        supportFile: "Supported File Type: *.pdf max 4MB",
        acceptedFileType: ["application/pdf"],
    },
    {
        title: "evaluation",
        label: "Financial Evaluation for Parents and Grandparents Sponsorship [IMM 5768]",
        supportFile: "Supported File Type: *.pdf max 4MB",
        acceptedFileType: ["application/pdf"],
    },
    {
        title: "income",
        label: "Income Sources for the Sponsorship of Parents and Grandparents",
        supportFile: "Supported File Type: *.pdf max 4MB",
        acceptedFileType: ["application/pdf"],
    },
    {
        title: "generic",
        label: "Generic Application Form for Canada",
        supportFile: "Supported File Type: *.pdf max 4MB",
        acceptedFileType: ["application/pdf"],
    },
    {
        title: "PR",
        label: "Status in Canada - Permanent Resident Card (both sides)",
        supportFile: "Supported File Type: *.pdf max 4MB",
        acceptedFileType: ["application/pdf"],
    },
    {
        title: "landing",
        label: "Status in Canada - Record of Landing",
        supportFile: "Supported File Type: *.pdf max 4MB",
        acceptedFileType: ["application/pdf"],
    },
    {
        title: "confirm",
        label: "Status in Canada - Confirmation of Permanent Residence",
        supportFile: "Supported File Type: *.pdf max 4MB",
        acceptedFileType: ["application/pdf"],
    },
    {
        title: "card",
        label: "Status in Canada - Canadian citizenship certificate or card",
        supportFile: "Supported File Type: *.pdf max 4MB",
        acceptedFileType: ["application/pdf"],
    },
    {
        title: "birth",
        label: "Status in Canada - Canadian birth certificate",
        supportFile: "Supported File Type: *.pdf max 4MB",
        acceptedFileType: ["application/pdf"],
    },
    {
        title: "canadaPassport",
        label: "Status in Canada - Canadian passport",
        supportFile: "Supported File Type: *.pdf max 4MB",
        acceptedFileType: ["application/pdf"],
    },
    {
        title: "secure",
        label: "Status in Canada - Secure certificate of Indian status",
        supportFile: "Supported File Type: *.pdf max 4MB",
        acceptedFileType: ["application/pdf"],
    },
    {
        title: "undertaking",
        label: "Application to Sponsor, Sponsorship Agreement and Undertaking",
        supportFile: "Supported File Type: *.pdf max 4MB",
        acceptedFileType: ["application/pdf"],
    },
    {
        title: "medicals",
        label: "Proof of upfront medical exam",
        supportFile: "Supported File Type: *.pdf max 4MB",
        acceptedFileType: ["application/pdf"],
    },
    {
        title: "rcmp",
        label: "PCC (RCMP Plus origin of Country)",
        supportFile: "Supported File Type: *.pdf max 4MB",
        acceptedFileType: ["application/pdf"],
    },
    {
        title: "relationship",
        label: "Proof Of Relationship",
        supportFile: "Supported File Type: *.pdf max 4MB",
        acceptedFileType: ["application/pdf"],
    },
    {
        title: "evaluation",
        label: "Financial Evaluation for Parents and Grandparents Sponsorship [IMM 5768]",
        supportFile: "Supported File Type: *.pdf max 4MB",
        acceptedFileType: ["application/pdf"],
    },
    {
        title: "cert",
        label: "Marriage Certificate",
        supportFile: "Supported File Type: *.pdf max 4MB",
        acceptedFileType: ["application/pdf"],
    },
    {
        title: "cv",
        label: "Resume or CV",
        supportFile: "Supported File Type: *.pdf max 4MB",
        acceptedFileType: ["application/pdf"],
    },
    {
        title: "process",
        label: "Processing Fees",
        supportFile: "Supported File Type: *.pdf max 4MB",
        acceptedFileType: ["application/pdf"],
    },
];
export const sponsorVisitDocsList = [
    {
        title: "jobLetter",
        label: "Job Letter",
        supportFile: "Supported File Type: *.pdf max 4MB",
        acceptedFileType: ["application/pdf"],
    },
    {
        title: "sponsorBankAcc",
        label: "Bank Account",
        supportFile: "Supported File Type: *.pdf max 4MB",
        acceptedFileType: ["application/pdf"],
    },
    {
        title: "sponsorProof",
        label: "Proof of Relation",
        supportFile: "Supported File Type: *.pdf max 4MB",
        acceptedFileType: ["application/pdf"],
    },
    {
        title: "substantiateTravelReason",
        label: "Substantiate reason for travelling",
        supportFile: "Supported File Type: *.pdf max 4MB",
        acceptedFileType: ["application/pdf"],
    },
    {
        title: "accommodation",
        label: "Place of Accommodation",
        supportFile: "Supported File Type: *.pdf max 4MB",
        acceptedFileType: ["application/pdf"],
    },
];
export const outsideVisaList = [
    {
        title: "visa",
        label: "Visa Copy if any",
        supportFile: "Supported File Type: *.pdf max 4MB",
        acceptedFileType: ["application/pdf"],
    },
];
export const extendVisitDocsList = [
    {
        title: "extendVisa",
        label: "Previous Visa Copy",
        supportFile: "Supported File Type: *.pdf max 4MB",
        acceptedFileType: ["application/pdf"],
        isMandatory: true,
    },
];
export const lmiaDocsList = [
    {
        title: "business",
        label: "Valid Business License",
        supportFile: "Supported File Type: *.pdf max 4MB",
        acceptedFileType: ["application/pdf"],
        isMandatory: true,
    },
    {
        title: "pd7a",
        label: "PD7A (Statement of Account)",
        supportFile: "Supported File Type: *.pdf max 4MB",
        acceptedFileType: ["application/pdf"],
        isMandatory: true,
    },
    {
        title: "info",
        label: "S100 and S125 Income Statement Information",
        supportFile: "Supported File Type: *.pdf max 4MB",
        acceptedFileType: ["application/pdf"],
        isMandatory: true,
    },
    {
        title: "incorporation",
        label: "Certificate of Incorporation",
        supportFile: "Supported File Type: *.pdf max 4MB",
        acceptedFileType: ["application/pdf"],
        isMandatory: true,
    },
    {
        title: "article",
        label: "Notice of Article",
        supportFile: "Supported File Type: *.pdf max 4MB",
        acceptedFileType: ["application/pdf"],
        isMandatory: true,
    },
    {
        title: "remunerate",
        label: "T4 Summary of remuneration",
        supportFile: "Supported File Type: *.pdf max 4MB",
        acceptedFileType: ["application/pdf"],
        isMandatory: true,
    },
    {
        title: "assess",
        label: "Notice of Assessment",
        supportFile: "Supported File Type: *.pdf max 4MB",
        acceptedFileType: ["application/pdf"],
        isMandatory: true,
    },
];
