import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { LOGO_PATH } from "src/constants/pageSection";
import "./Logo.css";

Logo.propTypes = {
  sx: PropTypes.object,
};

export default function Logo({ sx }) {
  return (
    <Link to="/" className="navbar-logo auto-margin">
      <img src={LOGO_PATH} alt="logo" className="img-logo" />
    </Link>
  );
}
