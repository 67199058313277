import { Button, Step, StepLabel, Stepper } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Spin } from "antd";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { AppCard } from "src/common/index.js";
import { getActiveStepContent, steps } from "src/common/StepperComponent.js";
import { eFileStatus, getFileAssignee } from "src/constants/actionTypes.js";
import * as api from "../../api/index.js";
import Success from "./Success";
const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        "& .MuiStepIcon-root.Mui-completed": { color: "#007B55" },
        "& .MuiStepLabel-label": { color: "#594ae3" },
    },
    StepLabel: {
        color: "red",
    },
    button: {
        marginRight: theme.spacing(1),
        border: "1px solid #b2b1c1",
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
}));

export default function HorizontalLinearStepper({ category }) {
    const [isLoading, setIsLoading] = useState(false);
    const user = JSON.parse(localStorage.getItem("profile"));
    const [postData, setPostData] = useState({
        clientName: user?.userName,
        category: category.title,
        email: user?.userEmail,
        fileStatus: eFileStatus.VerifyPending,
        assignee: getFileAssignee(category),
        missingDocs: [],
        emailSent: false,
    });
    const fetchDataAction = async () => {
        setIsLoading(true);
        let updatePost = await api.updatePost(
            localStorage.getItem("newFilePostID"),
            {
                ...postData,
                name: user?.userEmail,
            }
        );
        if (updatePost) {
            setIsLoading(false);
        }
    };
    const classes = useStyles();
    const [hasMissingDoc, setHasMissingDoc] = useState(true);
    const [enable, setEnable] = useState("");
    const [retained, setRetained] = useState(false);
    const [notPaid, setNotPaid] = useState(true);
    const [activeStep, setActiveStep] = useState(0);

    const handleNext = () => {
        if (activeStep === steps.length - 1) {
            postData.emailSent = true;
            fetchDataAction();
        }
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };
    const history = useNavigate();
    const handleBack = () => {
        if (activeStep === 0) {
            history("/pricing");
        }
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };
    const sendDataToParent = (data) => {
        if (postData.basicInfoClient) setEnable(true);
        if (postData.retainerDetails) setRetained(true);
        if (postData.payment) setNotPaid(false);
        if (postData.enableFinish) {
            setHasMissingDoc(false);
            data.missingDocs = [];
        }
        setPostData(data);
    };
    return (
        <div className={classes.root}>
            <Stepper className={classes.root} activeStep={activeStep}>
                {steps.map((label, index) => {
                    const stepProps = {};
                    const labelProps = {};
                    return (
                        <Step key={label} {...stepProps}>
                            <StepLabel
                                className={classes.StepLabel}
                                {...labelProps}
                            >
                                {label}
                            </StepLabel>
                        </Step>
                    );
                })}
            </Stepper>
            <div>
                {activeStep === steps.length ? (
                    isLoading ? (
                        <div className="textCenter">
                            <Spin tip="Please wait..." />
                        </div>
                    ) : (
                        <Success />
                    )
                ) : (
                    <div>
                        <AppCard
                            css="upload-step-card"
                            content={
                                <>
                                    {getActiveStepContent(
                                        activeStep,
                                        category,
                                        postData,
                                        sendDataToParent
                                    )}
                                    <div>
                                        <Button
                                            disabled={
                                                (activeStep === 2 && notPaid) ||
                                                activeStep === 3
                                            }
                                            onClick={handleBack}
                                            className={classes.button}
                                        >
                                            Back
                                        </Button>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            disabled={
                                                (activeStep === 0 && !enable) ||
                                                (activeStep === 1 &&
                                                    !retained) ||
                                                (activeStep === 2 && notPaid) ||
                                                (activeStep === 3 &&
                                                    hasMissingDoc)
                                            }
                                            onClick={handleNext}
                                            className={classes.button}
                                        >
                                            {activeStep === steps.length - 1
                                                ? "Finish"
                                                : "Next"}
                                        </Button>
                                    </div>
                                </>
                            }
                        />
                    </div>
                )}
            </div>
        </div>
    );
}
