export const PGWPDocsList = [
    {
        title: "permit",
        label: "All Study Permits",
        supportFile: "Supported File Type: *.pdf max 4MB",
        acceptedFileType: ["application/pdf"],
    },
    {
        title: "bank",
        label: "3 Months Bank Statement",
        supportFile: "Supported File Type: *.pdf max 4MB",
        acceptedFileType: ["application/pdf"],
    },
    {
        title: "refusal",
        label: "Refusal Letter,",
        supportFile: "Supported File Type: *.pdf max 4MB",
        acceptedFileType: ["application/pdf"],
    },
    {
        title: "marriage",
        label: "Marriage Certificate, if applicable",
        supportFile: "Supported File Type: *.pdf max 4MB",
        acceptedFileType: ["application/pdf"],
        isMandatory: true,
    },
    {
        title: "can",
        label: "All Educational Documents, (Canada)",
        supportFile: "Supported File Type: *.pdf max 4MB",
        acceptedFileType: ["application/pdf"],
    },
    {
        title: "official",
        label: "Official Transcript ",
        supportFile: "Supported File Type: *.pdf max 4MB",
        acceptedFileType: ["application/pdf"],
    },
    {
        title: "LOC",
        label: "Course Completion Letter",
        supportFile: "Supported File Type: *.pdf max 4MB",
        acceptedFileType: ["application/pdf"],
    },
    {
        title: "emp",
        label: "Course Employment Letter",
        supportFile: "Supported File Type: *.pdf max 4MB",
        acceptedFileType: ["application/pdf"],
    },
    {
        title: "paystub",
        label: "3 Recent Pay Slips",
        supportFile: "Supported File Type: *.pdf max 4MB",
        acceptedFileType: ["application/pdf"],
    },
    {
        title: "break",
        label: "School Leaving Support Letter, if applicable",
        supportFile: "Supported File Type: *.pdf max 4MB",
        acceptedFileType: ["application/pdf"],
    },
    {
        title: "noa",
        label: "T4 or NOA, if applicable ",
        supportFile: "Supported File Type: *.pdf max 4MB",
        acceptedFileType: ["application/pdf"],
    },
];
