export const newSpousalDocsList = [
    {
        title: "marriage",
        label: "Marriage Certificate",
        supportFile: "Supported File Type: *.pdf max 4MB",
        acceptedFileType: ["application/pdf"],
    },
    {
        title: "ritual",
        label: "Marriage and other rituals Pics",
        supportFile: "Click to follow the specification for digital photo",
        acceptedFileType: ["image/jpeg", "image/png", "image/jpg"],
    },
    {
        title: "gifts",
        label: "Gifts Receipts sent to each other",
        supportFile: "Supported File Type: *.pdf max 4MB",
        acceptedFileType: ["application/pdf"],
    },
    {
        title: "money",
        label: "Money transfer receipts (if sent to each other)",
        supportFile: "Supported File Type: *.pdf max 4MB",
        acceptedFileType: ["application/pdf"],
    },
    {
        title: "chat",
        label: "Chat history",
        supportFile: "Supported File Type: *.pdf max 4MB",
        acceptedFileType: ["application/pdf"],
    },
    {
        title: "call",
        label: "Phone Call history",
        supportFile: "Supported File Type: *.pdf max 4MB",
        acceptedFileType: ["application/pdf"],
    },
    {
        title: "educ",
        label: "Educational Documents",
        supportFile: "Supported File Type: *.pdf max 4MB",
        acceptedFileType: ["application/pdf"],
    },
    {
        title: "work",
        label: "Work History",
        supportFile: "Supported File Type: *.pdf max 4MB",
        acceptedFileType: ["application/pdf"],
    },
    {
        title: "stub",
        label: "Job Letter and Pay Slips",
        supportFile: "Supported File Type: *.pdf max 4MB",
        acceptedFileType: ["application/pdf"],
    },
    {
        title: "noa",
        label: "NOA and T4, if any",
        supportFile: "Supported File Type: *.pdf max 4MB",
        acceptedFileType: ["application/pdf"],
    },
    {
        title: "sbank",
        label: "Bank Statements",
        supportFile: "Supported File Type: *.pdf max 4MB",
        acceptedFileType: ["application/pdf"],
    },
    {
        title: "property",
        label: "Property Assessment Papers, if applicable",
        supportFile: "Supported File Type: *.pdf max 4MB",
        acceptedFileType: ["application/pdf"],
    },
    {
        title: "refusal",
        label: "Refusal Letter of any country including Canada, if any",
        supportFile: "Supported File Type: *.pdf max 4MB",
        acceptedFileType: ["application/pdf"],
    },
];
export const sponsorSpouseDocsList = [
    {
        title: "sponsor",
        label: "Passport of Sponsor",
        supportFile: "Supported File Type: *.pdf max 4MB",
        acceptedFileType: ["application/pdf"],
    },
    {
        title: "canadastat",
        label: "Canadian Status Document (work or study permit)",
        supportFile: "Supported File Type: *.pdf max 4MB",
        acceptedFileType: ["application/pdf"],
    },
    {
        title: "enrollment",
        label: "Enrollment letter, College offer letter",
        supportFile: "Supported File Type: *.pdf max 4MB",
        acceptedFileType: ["application/pdf"],
    },
    {
        title: "transcript",
        label: "College official transcripts, if student",
        supportFile: "Supported File Type: *.pdf max 4MB",
        acceptedFileType: ["application/pdf"],
    },
    {
        title: "employment",
        label: "Employment Letter",
        supportFile: "Supported File Type: *.pdf max 4MB",
        acceptedFileType: ["application/pdf"],
    },
    {
        title: "paystub",
        label: "3 Recent Pay Slips",
        supportFile: "Supported File Type: *.pdf max 4MB",
        acceptedFileType: ["application/pdf"],
    },
    {
        title: "sponsorbank",
        label: "Bank Statement",
        supportFile: "Supported File Type: *.pdf max 4MB",
        acceptedFileType: ["application/pdf"],
    },
    {
        title: "sponsored",
        label: "Other educational documents",
        supportFile: "Supported File Type: *.pdf max 4MB",
        acceptedFileType: ["application/pdf"],
    },
    {
        title: "t4",
        label: "All T4(s), if applicable",
        supportFile: "Supported File Type: *.pdf max 4MB",
        acceptedFileType: ["application/pdf"],
    },
];
export const extendWorkDocsList = [
    {
        title: "emp",
        label: "Employment Letter of Current Employer",
        supportFile: "Supported File Type: *.pdf max 4MB",
        acceptedFileType: ["application/pdf"],
    },
    {
        title: "bank",
        label: "Bank Statement",
        supportFile: "Supported File Type: *.pdf max 4MB",
        acceptedFileType: ["application/pdf"],
    },
    {
        title: "work",
        label: "Current Work Permit",
        supportFile: "Supported File Type: *.pdf max 4MB",
        acceptedFileType: ["application/pdf"],
    },
    {
        title: "marriage",
        label: "Marriage Certificate",
        supportFile: "Supported File Type: *.pdf max 4MB",
        acceptedFileType: ["application/pdf"],
    },
    {
        title: "lmia",
        label: "LMIA Letter, if applicable",
        supportFile: "Supported File Type: *.pdf max 4MB",
        acceptedFileType: ["application/pdf"],
    },
    {
        title: "workHistory",
        label: "Work History",
        supportFile: "Supported File Type: *.pdf max 4MB",
        acceptedFileType: ["application/pdf"],
    },
    {
        title: "refusal",
        label: "Refusal Letter of any country including Canada, if any",
        supportFile: "Supported File Type: *.pdf max 4MB",
        acceptedFileType: ["application/pdf"],
    },
];
