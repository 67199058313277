import { MessageFilled } from "@ant-design/icons";
export const text = "na";
export const immigrationInfo = [
    {
        key: "visit",
        title: "Visit in Canada",
        description: (
            <>
                <strong>
                    If you want to visit Canada, if you need to transit through
                    Canad, if you want to run your business in Canada, if you
                    want to extend your stay in Canada on a temporary resident
                    visa,
                </strong>
                <div>Contact us now so we can assist you further.</div>
            </>
        ),
    },
    {
        key: "super",
        title: "Super Visa",
        description: (
            <>
                <strong>
                    If you need a new work permit, if you need to check Labor
                    Market International Assessment for working in Canada, if
                    you need to extend your work permit, if you want to hire
                    foreign workers,
                </strong>
                <div>Contact us now so we can assist you further.</div>
            </>
        ),
    },
    {
        key: "study",
        title: "Study in Canada",
        description: (
            <>
                <strong>
                    If you need a study permit, your acknowledgment letter must
                    be from a DLI. If it isn’t, IRCC will refuse your
                    application.
                </strong>
                <div>
                    Contact us now should you want to study in Canada so we can
                    assist you further.
                </div>
            </>
        ),
    },
    {
        key: "work",
        title: "Work in Canada",
        description: (
            <>
                <strong>
                    If you need a new work permit, if you need to check Labor
                    Market International Assessment for working in Canada, if
                    you need to extend your work permit, if you want to hire
                    foreign workers,
                </strong>
                <div>Contact us now so we can assist you further.</div>
            </>
        ),
    },
    // {
    //   key: "lmia",
    //   title: "LMIA Stream (Business Professionals/Senior Manager Opportunities)",
    //   description: (
    //     <>
    //       <strong>Who is an Owner Operator?</strong>
    //       <div>
    //         As the name proposes, Owner-Operator, is a term used to define a
    //         foreign national who owns the controlling interest (50.1%) in the
    //         business, in simple words – operate the business they own.
    //       </div>
    //     </>
    //   ),
    // },
];
export const immigrationForumInfo = [
    {
        key: "express-entry",
        title: "Immigration to Canada",
    },
    {
        key: "study",
        title: "Temporary Entry to Canada",
    },
    {
        key: "family",
        title: "Working in Canada",
    },
];

export const forumData = [
    {
        href: "",
        title: "asdasd",
        avatar: (
            <MessageFilled style={{ fontSize: "30px", color: "#232425" }} />
        ),
        description: "asfasf",
        content: "asfasfas",
    },
    {
        href: "",
        title: "asdasd",
        avatar: (
            <MessageFilled style={{ fontSize: "30px", color: "#232425" }} />
        ),
        description: "asfasf",
        content: "asfasfas",
    },
    {
        href: "",
        title: "asdasd",
        avatar: (
            <MessageFilled style={{ fontSize: "30px", color: "#232425" }} />
        ),
        description: "asfasf",
        content: "asfasfas",
    },
    {
        href: "",
        title: "asdasd",
        avatar: (
            <MessageFilled style={{ fontSize: "30px", color: "#232425" }} />
        ),
        description: "asfasf",
        content: "asfasfas",
    },
    {
        href: "",
        title: "asdasd",
        avatar: (
            <MessageFilled style={{ fontSize: "30px", color: "#232425" }} />
        ),
        description: "asfasf",
        content: "asfasfas",
    },
    {
        href: "",
        title: "asdasd",
        avatar: (
            <MessageFilled style={{ fontSize: "30px", color: "#232425" }} />
        ),
        description: "asfasf",
        content: "asfasfas",
    },
];
// Array.from({
//   length: 23,
// }).map((_, i) => ({
//   href: "https://ant.design",
//   title: `ant design part ${i}`,
//   avatar: "https://joeschmoe.io/api/v1/random",
//   description:
//     "Ant Design, a design language for background applications, is refined by Ant UED Team.",
//   content:
//     "We supply a series of design principles, practical patterns and high quality design resources (Sketch and Axure), to help people create their product prototypes beautifully and efficiently.",
// }));
