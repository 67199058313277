import {
    newSpousalDocsList,
    sponsorSpouseDocsList,
} from "./spouseUploadDocsList";
import { newStudyDocsList } from "./studyUploadDocsList";
import { newVisitDocsList, sponsorVisitDocsList } from "./visitUploadDocsList";

export const clientInfoDoc = [
    {
        title: "clientExcel",
        label: "Upload the filled Client information Detail (Excel sheet)  ",
        supportFile: "Supported File Type: *.xlsx max 4MB",
        acceptedFileType: [
            "application/vnd.ms-excel",
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        ],
        isMandatory: true,
    },
];
export const passportUploadDocsList = [
    {
        title: "passport",
        label: "Current Passport and Previous Passport if any",
        supportFile: "Supported File Type: *.pdf max 4MB",
        acceptedFileType: ["application/pdf"],
        isMandatory: true,
    },
];
export const studyVisitUploadDocsList = [
    {
        title: "education",
        label: "Recent Education Transcript",
        supportFile: "Supported File Type: *.pdf max 4MB",
        acceptedFileType: ["application/pdf"],
        isMandatory: true,
    },
    {
        title: "proofOfFunds",
        label: "Proof of Funds - ITR (Parents or student)",
        supportFile: "Supported File Type: *.pdf max 4MB",
        acceptedFileType: ["application/pdf"],
        isMandatory: true,
    },
    {
        title: "sponsorship",
        label: "Sponsorship Letters",
        supportFile: "Supported File Type: *.pdf max 4MB",
        acceptedFileType: ["application/pdf"],
    },
];

export const affidavitFileUploadTitle = [
    {
        title: "affidavit",
        label: "Supporting affidavit",
        supportFile: "Supported File Type: *.pdf max 4MB",
        acceptedFileType: ["application/pdf"],
        isMandatory: true,
    },
];
export const picFileUploadTitle = [
    {
        title: "photo",
        label: "Digital Photo",
        supportFile: "Click to follow the specification for digital photo",
        acceptedFileType: ["image/jpeg", "image/png", "image/jpg"],
        isMandatory: true,
    },
];
export const filesDocumentUploadTitle = passportUploadDocsList.concat(
    clientInfoDoc,
    studyVisitUploadDocsList,
    newVisitDocsList,
    sponsorVisitDocsList,
    newStudyDocsList,
    newSpousalDocsList,
    sponsorSpouseDocsList,
    affidavitFileUploadTitle
);
