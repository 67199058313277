import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import PaymentIcon from "@mui/icons-material/Payment";
import { Button } from "@mui/material";
import { Alert, Col, Row, Spin } from "antd";
import { useEffect, useRef, useState } from "react";
import { AppCard } from "src/common/index.js";
import {
    initializeCard,
    SQUARE_SOURCE,
    tokenize,
} from "src/constants/paymentConfig.js";
import * as api from "../../api/index.js";
export default function SquarePayment({
    sendDataToParent,
    postData,
    category,
}) {
    const [payment, setPayment] = useState(false);
    const [loaded, setLoaded] = useState(false);
    const isMounted = useRef(true);

    useEffect(() => {
        squareScriptLoad();
        return () => {
            isMounted.current = false;
        };
    }, []);
    const [isLoading, setIsLoading] = useState(true);
    const squareScriptLoad = () => {
        window.addEventListener("load", getContent);
        if (typeof window !== undefined) {
            let sqPaymentScript = document.createElement("script");
            sqPaymentScript.id = "paymentScript";
            sqPaymentScript.src = SQUARE_SOURCE.DEV.SOURCE;
            sqPaymentScript.type = "text/javascript";
            sqPaymentScript.async = false;
            sqPaymentScript.onload = async () => {
                setIsLoading(true);
                setLoaded(true);
                if (!window.Square) {
                    throw new Error("Square.js failed to load properly");
                }
                const payments = window.Square.payments(
                    SQUARE_SOURCE.DEV.SQUARE_APP_ID,
                    SQUARE_SOURCE.DEV.SQUARE_LOCATION_ID
                );
                let card;
                try {
                    card = await initializeCard(payments);
                    setIsLoading(false);
                } catch (e) {
                    console.error("Initializing Card failed", e);
                    return;
                }
                const cardButton = document.getElementById("card-button");
                cardButton.addEventListener("click", async function (event) {
                    await handlePaymentMethodSubmission(event, card);
                });
            };

            if (!document.getElementById("paymentScript")) {
                document.body.appendChild(sqPaymentScript);
            }
        }
    };

    const getContent = () => {
        setLoaded(true);
    };
    async function handlePaymentMethodSubmission(event, paymentMethod) {
        event.preventDefault();
        try {
            const token = await tokenize(paymentMethod);
            const paymentResults = await createPayment(token);
        } catch (e) {
            console.error(e.message);
        }
    }
    // Call this function to send a payment token, buyer name, and other details
    // to the project server code so that a payment can be created with
    // Payments API
    async function createPayment(token) {
        let fees = parseInt(category.fee.replace(/[^0-9]/g, "")) / 100;
        const body = {
            locationId: 0,
            amount: fees,
            sourceId: token,
        };
        const paymentResponse = await api.createPayment(body);

        if (paymentResponse.status === 200) {
            postData.payment = {
                paid: true,
                amount: fees,
                paymentDate: new Date().toISOString(),
            };
            sendDataToParent(postData);
            setPayment(true);
            let updatePost = await api.updatePost(
                localStorage.getItem("newFilePostID"),
                {
                    ...postData,
                    name: postData.clientName,
                }
            );
        }
    }
    return (
        loaded && (
            <div>
                {!payment && (
                    <>
                        <h1 className="textCenter">Enter card details</h1>
                        <form id="payment-form">
                            <div id="card-container"></div>
                            {isLoading ? (
                                <div className="textCenter">
                                    <Spin />
                                </div>
                            ) : (
                                <Row>
                                    <Col span={12} offset={10}>
                                        <Button
                                            size="large"
                                            id="card-button"
                                            type="button"
                                            color="primary"
                                            variant="contained"
                                            disabled={payment}
                                            startIcon={<PaymentIcon />}
                                        >
                                            Pay <AttachMoneyIcon />
                                            {category.fee}
                                        </Button>
                                    </Col>
                                </Row>
                            )}
                        </form>
                        <div id="payment-status-container"></div>
                    </>
                )}
                {payment && (
                    <>
                        <Alert
                            className="alert-paid"
                            message="Payment Success"
                            description="Thank you. Your payment has been made successfully! Kindly go through the checklist below to upload the relevant documents in the next step!"
                            type="success"
                            showIcon
                        />
                        <AppCard
                            title={`Checklist for ${category.subtitle}`}
                            css="upload-step-card"
                            content={
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                    }}
                                >
                                    <div>
                                        <strong>
                                            {category.checklist.Applicant.title}
                                        </strong>
                                        <ol>
                                            {category.docsUpload
                                                .concat(category.picsUpload)
                                                .map((item, index) => (
                                                    <li key={index}>
                                                        {item.label}
                                                    </li>
                                                ))}
                                        </ol>
                                    </div>

                                    {category?.sponsorDocs && (
                                        <div>
                                            <strong>
                                                {
                                                    category.checklist.Sponsor
                                                        .title
                                                }
                                            </strong>
                                            <ol>
                                                {category.sponsorDocs.map(
                                                    (item, index) => (
                                                        <li key={index}>
                                                            {item.label}
                                                        </li>
                                                    )
                                                )}
                                            </ol>
                                        </div>
                                    )}
                                </div>
                            }
                        />
                    </>
                )}
            </div>
        )
    );
}
