import {
    BuildFilled,
    CheckCircleTwoTone,
    CheckOutlined,
    FileProtectOutlined,
    HeartOutlined,
    HeatMapOutlined,
    PropertySafetyTwoTone,
    SketchOutlined,
    SolutionOutlined,
    StarFilled,
    StarOutlined,
} from "@ant-design/icons";
import { ROUTE_URL } from "src/common/values";
export const PAGE_SECTION = {
    ABOUT: {
        valuesList: [
            {
                icon: <FileProtectOutlined className="values-icon" />,
                title: "ICCRC LICENSED",
                description:
                    "Robin Kapoor, Founder and Principal has been accredited with ICCRC as a Regulated Canadian Immigration Consultant R523621.",
            },
            {
                icon: <SketchOutlined className="values-icon" />,
                title: "Experienced",
                description:
                    "Robin Kapoor has extensive experience in successfully delivering results. He had worked with few immigration companies prior to opening his new firm. He worked with Canoscope Immigration as a Managing Partner.",
            },
            {
                icon: <HeartOutlined className="values-icon" />,
                title: "Boutique Services",
                description:
                    "Specializing in immigration program with a high approval rate since 2017. He has assisted clients under the Global Skills Strategy Program whereas he has assisted many IT professionals to relocate from one country to another.",
            },
            {
                icon: <SolutionOutlined className="values-icon" />,
                title: "Canada Immigration ONLY",
                description:
                    "We at ExtendTheVisa only focus on CANADA Immigration which is why we are different from others who are dealing with multiple country pathway options.",
            },
            {
                icon: <HeatMapOutlined className="values-icon" />,
                title: "Only one Motto",
                description:
                    "Robin abides by his favorite saying, ”Making Dreams Come Alive” in his field of practice.",
            },
            {
                icon: <StarOutlined className="values-icon" />,
                title: "Success Guaranteed",
                description:
                    "Our Success rate of visa approval is high due to our experienced professional approach to make your journey hassle-free",
            },
        ],
    },
    TEAMS: {
        teamMembers: [
            {
                memberImg:
                    "https://res.cloudinary.com/asset-aivc/image/upload/v1659566435/p1_n4hf0i.jpg",
                name: "Robin Kapoor",
                designation: "Founder and Principal",
                description:
                    "Robin Kapoor, RCIC R523621 is the Principal and Co-Founder at ExtendTheVisa. Robin is initially from Ludhiana, Punjab, India and earned recognition in Mechanical Engineering (2011) from BCIT situated in Burnaby, BC. He has finished his Immigration professional course in 2017 and has effectively become an RCIC to help customers.",
            },
            // {
            //     memberImg:
            //         "https://res.cloudinary.com/asset-aivc/image/upload/v1659566433/p3_nhfpes.jpg",
            //     name: "Bishan Singh",
            //     designation: "Office Supervisor",
            //     description:
            //         "Bishan is double Masters from Fairleigh Dickinson University, Vancouver and working as office supervisor with ExtendTheVisa since 2020. He has been employed as a student before handing the crucial role in the orgranization. He is currently double Masters degree holder from Fairleigh Dickinson University and has vast experience in dealing with student visa applications for Canada. He was employed prior in 2018 with us but was working on a different project before embarking journey with us.",
            // },
            {
                memberImg:
                    "https://res.cloudinary.com/asset-aivc/image/upload/v1659566434/p2_rbkpdx.jpg",
                name: "Monish",
                designation: "Office Supervisor",
                description:
                    "Monish joined AlVC in 2021 but he has former experience handling immigration files. His speciality is in Canadian Experience Class applications and assisting clients to successfuly receive their PR. He is the strength of ExtendTheVisa.",
            },
        ],
    },
    SERVICES: {
        serviceList: [
            {
                title: "Visitor Visa Application",
                description: `<ul><li>Tourist visa</li>
    <li>Super visa</li><li>Transit visa</li><li>
Business visa</li></ul>`,
                imgSrc: "/static/icons/visit.png",
                icon: <CheckOutlined className="values-icon" />,
            },
            {
                title: "Study Permit Application",
                description: `<ul><li>New study permit</li>
    <li>Study permit extension</li><li>Work permit for student</li></ul>`,
                imgSrc: "/static/icons/extend_study.png",
                icon: <CheckOutlined className="values-icon" />,
            },
            {
                title: "Work Permit Application",
                description: `<ul><li>Work Permit Outside Canada</li>
    <li>Work Permit Inside Canada</li><li>New work permit application</li><li>Extend work permit application</li></ul>`,
                imgSrc: "/static/icons/lmia.png",
                icon: <CheckOutlined className="values-icon" />,
            },
        ],
    },
    CONTACT: {
        acknowledge: "Thank you for contacting us.",
        mailSuccessMessage:
            "One of our agents will contact you shortly! If you have any questions, call us or WhatsApp at +1778-986-2021.",
    },
    MAIN: {
        STATS: {
            records: [
                {
                    title: "Customers",
                    count: "1500+",
                },
                {
                    title: "Approvals",
                    count: "1000+",
                },
                {
                    title: "Applications Processed",
                    count: "1175+",
                },
            ],
        },
        EXPERTISE: {
            expertiseList: [
                {
                    title: "Success Guaranteed",
                    description:
                        "Google 5 star rated - Skilled hands-on expertise with immigration pathways",
                    avatar: (
                        <CheckCircleTwoTone
                            style={{ fontSize: "2em" }}
                            twoToneColor="#594ae3"
                        />
                    ),
                },
                {
                    title: "Experienced",
                    description:
                        "Reliable , Expertise , Highly Oriented , No EGO , Friendly , Promising, 3+ years of experience, Handled tough cases, Delivered many approvals",
                    avatar: (
                        <StarFilled
                            style={{ fontSize: "2em", color: "#594ae3" }}
                        />
                    ),
                },
                {
                    title: "ICCRC Licensed",
                    description:
                        "Regulated Consultant - R523621, Dealing specific to Canada Visa",
                    avatar: (
                        <PropertySafetyTwoTone
                            style={{ fontSize: "2em" }}
                            twoToneColor="#594ae3"
                        />
                    ),
                },
                {
                    title: "Custom Solutions",
                    description:
                        "Tailored to suit your needs, Never use standard approach, Bring the best solutions to meet your needs",
                    avatar: (
                        <BuildFilled
                            style={{ fontSize: "2em", color: "#594ae3" }}
                        />
                    ),
                },
            ],
        },
        STEPS: {
            steps: [
                {
                    title: "Register",
                    stepColor: "green",
                    description: "Create an account with us.",
                },
                {
                    title: "Upload Documents",
                    stepColor: "green",
                    description:
                        "Pay your fees and provide us with the documents to process the application.",
                },
                {
                    title: "Track Application Status",
                    stepColor: "blue",
                    description:
                        "Sign in to your account to track the application status.",
                },
            ],
        },
    },
};
export const ACTION_LINK = {
    CONTACT: {
        title: "Contact Us",
        subtitle: "Contact Us For More Info",
        action: "/contact-us",
    },
    PROGRAMS: { title: "Get Started", action: "/" + ROUTE_URL.Programs },
};
export const TITLE_LINK = {
    ACCOUNT: "My Account | ExtendTheVisa",
    DASHBOARD: "Dashboard | ExtendTheVisa",
    USER: "User | ExtendTheVisa",
    ABOUT: "About Us | ExtendTheVisa",
    CONTACT: "Contact Us | ExtendTheVisa",
    PRICING: "Pricing | ExtendTheVisa",
    SERVICES: "Our Services | ExtendTheVisa",
    PROGRAMS: "Pricing | ExtendTheVisa",
    APPLICATION: "File Details",
    DOCUMENT: "Documents Provided:",
    LOGIN: "Login | ExtendTheVisa",
    REGISTER: "Register | ExtendTheVisa",
};
export const LOGO_PATH = "/logos/extendthevisa-logos_white.png";
export const LOGO_PATH_BLUE = "/logos/extendthevisa-logos_transparent.png";
