import { Button, Form, Input, Radio, Select } from "antd";
import { useState } from "react";
import { successMsg } from "src/common";
import { visitPurposeList } from "src/constants/actionTypes";
import "./Eligibility.scss";
const { Option } = Select;
const layout = {
    wrapperCol: {
        span: 16,
    },
};
export default function VisitVisaNewEligibility({ ...props }) {
    const [form] = Form.useForm();

    const [location, setValue] = useState("");
    const [visitPurpose, setVisitPurpose] = useState(null);
    const onTypeChange = (value) => {
        setVisitPurpose(value);
    };
    const [formDisable, setFormDisable] = useState(null);
    const onFinish = (values) => {
        props.postData.basicInfoClient = values;
        props.sendDataToParent(props.postData);
        setFormDisable(true);
    };

    const onReset = () => {
        form.resetFields();
    };
    const onLocationChange = (e) => {
        setVisitPurpose(null);
        setValue(e.target.value);
    };
    return (
        <Form
            {...layout}
            form={form}
            name="control-hooks"
            className="basicInfoClient-form"
            onFinish={onFinish}
        >
            <Form.Item
                name="location"
                label="Where are you currently located?"
                rules={[{ required: true }]}
            >
                <Radio.Group onChange={onLocationChange} disabled={formDisable}>
                    <Radio value="Inside Canada"> Inside Canada</Radio>
                    <Radio value="Outside Canada">Outside Canada </Radio>
                </Radio.Group>
            </Form.Item>
            {location === "Outside Canada" && (
                <Form.Item
                    name="purpose"
                    label="Select purpose of visit "
                    rules={[{ required: true }]}
                >
                    <Select
                        placeholder="Select purpose"
                        onChange={onTypeChange}
                        disabled={formDisable}
                        allowClear
                    >
                        {visitPurposeList.map((item, index) => (
                            <Option key={index} value={item.text}>
                                {item.text}
                            </Option>
                        ))}
                    </Select>
                </Form.Item>
            )}
            {location === "Inside Canada" && (
                <>
                    <Form.Item
                        name="holdVisa"
                        rules={[
                            { required: true, message: "Select an option" },
                        ]}
                        label="Do you hold any Canadian visa (including valid or expired)?"
                    >
                        <Radio.Group disabled={formDisable}>
                            <Radio value="Yes"> Yes </Radio>
                            <Radio value="No">No</Radio>
                            <Radio value="Not Sure">Not sure</Radio>
                        </Radio.Group>
                    </Form.Item>
                </>
            )}
            {visitPurpose === "Other" && (
                <Form.Item label="Specify reason of visit" name="visitReason">
                    <Input disabled={formDisable} />
                </Form.Item>
            )}
            <Form.Item className="submitBtn" label="Submit">
                <Button type="primary" htmlType="submit" disabled={formDisable}>
                    Confirm
                </Button>
                <Button
                    htmlType="button"
                    onClick={onReset}
                    disabled={formDisable}
                >
                    Reset
                </Button>
            </Form.Item>
            {formDisable &&
                successMsg(
                    "Continue Next Step",
                    "Thank you for providing your inputs. You may proceed Next to read our terms and agreements to retain us for your application processing!",
                    { borderRadius: "10px" }
                )}
        </Form>
    );
}
