import { Layout, Result } from "antd";
import { Link } from "react-router-dom";
import { AppButton } from "src/common";
import { DASHBOARD_ROUTE_PATH } from "src/common/values";

export default function Success() {
    return (
        <Layout style={{ height: "60vh" }}>
            <Result
                status="success"
                title="Thank you for applying !"
                subTitle="You will get an email with further instructions."
                extra={
                    <Link to={DASHBOARD_ROUTE_PATH.CLIENT}>
                        <AppButton
                            title="Go to Account"
                            css="cover-btn"
                            type="primary"
                        />
                    </Link>
                }
            />
        </Layout>
    );
}
