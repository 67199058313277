import ExtendSpousalPREligibility from "src/components/StepForm/EligibilityCriteriaApplication/ExtendSpousalPREligibility";
import ExtendStudyVisaEligibility from "src/components/StepForm/EligibilityCriteriaApplication/ExtendStudyVisaEligibility";
import ExtendVisitVisaEligibility from "src/components/StepForm/EligibilityCriteriaApplication/ExtendVisitVisaEligibility";
import ExtendWorkVisaEligibility from "src/components/StepForm/EligibilityCriteriaApplication/ExtendWorkVisaEligibility";
import FamilySponsorship from "src/components/StepForm/EligibilityCriteriaApplication/FamilySponsorship";
import LMIAVisaEligibility from "src/components/StepForm/EligibilityCriteriaApplication/LMIAVisaEligibility";
import PGWPEligibility from "src/components/StepForm/EligibilityCriteriaApplication/PGWPEligibility";
import StudyVisaEligibility from "src/components/StepForm/EligibilityCriteriaApplication/StudyVisaEligibility";
import VisitVisaNewEligibility from "src/components/StepForm/EligibilityCriteriaApplication/VisitVisaNewEligibility";
import SquarePayment from "src/components/StepForm/SquarePayment";
import TermsAgreement from "src/components/StepForm/TermsAgreement";
import UploadDocs from "src/components/StepForm/UploadDocs/UploadDocs";
import { eCategory } from "../constants/actionTypes";

export const steps = [
    "Basic Info",
    "Retainer Agreement",
    "Make Payment",
    "Upload Documents",
];
export const categoryEligibilityContent = (
    postData,
    category,
    sendDataToParent
) => {
    switch (category) {
        case eCategory.NewVisit:
            return (
                <VisitVisaNewEligibility
                    postData={postData}
                    sendDataToParent={sendDataToParent}
                />
            );
        case eCategory.ExtendVisit:
            return (
                <ExtendVisitVisaEligibility
                    postData={postData}
                    sendDataToParent={sendDataToParent}
                />
            );
        case eCategory.Family:
            return (
                <FamilySponsorship
                    postData={postData}
                    sendDataToParent={sendDataToParent}
                />
            );
        case eCategory.NewStudy:
            return (
                <StudyVisaEligibility
                    postData={postData}
                    sendDataToParent={sendDataToParent}
                />
            );
        case eCategory.ExtendStudy:
            return (
                <ExtendStudyVisaEligibility
                    postData={postData}
                    sendDataToParent={sendDataToParent}
                />
            );
        case eCategory.PGWP:
            return (
                <PGWPEligibility
                    postData={postData}
                    sendDataToParent={sendDataToParent}
                />
            );
        case eCategory.LMIA:
            return (
                <LMIAVisaEligibility
                    postData={postData}
                    sendDataToParent={sendDataToParent}
                />
            );
        case eCategory.ExtendWork:
            return (
                <ExtendWorkVisaEligibility
                    postData={postData}
                    sendDataToParent={sendDataToParent}
                />
            );
        case eCategory.Spousal:
            return (
                <ExtendSpousalPREligibility
                    postData={postData}
                    sendDataToParent={sendDataToParent}
                />
            );
        default:
            return <></>;
    }
};

export function getActiveStepContent(
    activeStep,
    category,
    postData,
    sendDataToParent
) {
    switch (activeStep) {
        case 0:
            return categoryEligibilityContent(
                postData,
                category,
                sendDataToParent
            );
        case 1:
            return (
                <TermsAgreement
                    postData={postData}
                    sendDataToParent={sendDataToParent}
                />
            );
        case 2:
            return (
                <SquarePayment
                    postData={postData}
                    sendDataToParent={sendDataToParent}
                    category={category}
                />
            );
        case 3:
            return (
                <UploadDocs
                    category={category}
                    postData={postData}
                    sendDataToParent={sendDataToParent}
                />
            );

        default:
            return "Unknown step";
    }
}
