import { Button, Form, Radio, Select } from "antd";
import { useState } from "react";
import { successMsg } from "src/common";
import { workPermitType } from "src/constants/actionTypes";
import "./Eligibility.scss";
const layout = {
    wrapperCol: {
        span: 16,
    },
};
export default function LMIAVisaEligibility({ ...props }) {
    const [form] = Form.useForm();

    const { Option } = Select;
    const [formDisable, setFormDisable] = useState(null);
    const onFinish = (values) => {
        props.postData.basicInfoClient = values;
        props.sendDataToParent(props.postData);
        setFormDisable(true);
    };

    const onReset = () => {
        form.resetFields();
    };
    const [value, setValue] = useState("");
    const onLocationChange = (e) => {
        setValue(e.target.value);
    };
    const [permit, setPermit] = useState(null);
    const onTypeChange = (value) => {
        setPermit(value);
    };
    return (
        <Form
            {...layout}
            form={form}
            name="control-hooks"
            className="basicInfoClient-form"
            onFinish={onFinish}
        >
            <Form.Item name="location" label="Where are you currently located?">
                <Radio.Group
                    onChange={onLocationChange}
                    value={value}
                    disabled={formDisable}
                >
                    <Radio value="Inside Canada"> Inside Canada</Radio>
                    <Radio value="Outside Canada">Outside Canada </Radio>
                </Radio.Group>
            </Form.Item>
            <Form.Item name="permit" label="Select type of work permit ">
                <Select
                    placeholder="Select purpose"
                    onChange={onTypeChange}
                    allowClear
                    disabled={formDisable}
                >
                    {workPermitType.map((item) => (
                        <Option key={item.key} value={item.value}>
                            {item.value}
                        </Option>
                    ))}
                </Select>
            </Form.Item>

            {permit === "Open Work Permit" && (
                <Form.Item
                    label="Is your spouse studying or working in Canada?"
                    name="spouse"
                >
                    <Radio.Group disabled={formDisable}>
                        <Radio value="Yes">Yes</Radio>
                        <Radio value="No">No</Radio>
                    </Radio.Group>
                </Form.Item>
            )}
            {permit === "LMIA" && (
                <Form.Item label="Do you have valid LMIA?" name="lmia">
                    <Radio.Group disabled={formDisable}>
                        <Radio value="Yes">Yes</Radio>
                        <Radio value="No">No</Radio>
                    </Radio.Group>
                </Form.Item>
            )}
            <Form.Item className="submitBtn" label="Submit">
                <Button type="primary" htmlType="submit" disabled={formDisable}>
                    Confirm
                </Button>
                <Button
                    htmlType="button"
                    onClick={onReset}
                    disabled={formDisable}
                >
                    Reset
                </Button>
            </Form.Item>
            {formDisable &&
                successMsg(
                    "Continue Next Step",
                    "Thank you for providing your inputs. You may proceed Next to read our terms and agreements to retain us for your application processing!",
                    { borderRadius: "10px" }
                )}
        </Form>
    );
}
