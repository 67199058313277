import axios from "axios";

const BASE_URL = {
    PROD: "https://backendapp-extendvisa-prod.onrender.com",
    STAGING: "https://backendapp-extendvisa.onrender.com/",
    DEV: "http://localhost:5000",
};
const API = axios.create({
    baseURL: BASE_URL.PROD,
});
API.interceptors.request.use((req) => {
    if (localStorage.getItem("profile")) {
        req.headers.Authorization = `Bearer ${
            JSON.parse(localStorage.getItem("profile")).token
        }`;
    }
    return req;
});
export const signIn = (formData) => API.post("/users/signIn", formData);
export const signUp = (formData) => API.post("/users/signUp", formData);

export const fetchPosts = () => API.get("/posts");
export const createPost = (newPost) => API.post("/posts", newPost);
export const getPost = (email) => API.get(`/posts/${email}`);
export const likePost = (id) => API.patch(`/posts/${id}/likePost`);
export const updatePost = (id, updatedPost) =>
    API.patch(`/posts/${id}`, updatedPost);
export const deletePost = (id) => API.delete(`/posts/${id}`);

export const fetchNotes = () => API.get("/notes");
export const createFollowUp = (newFollowUp) => API.post("/notes", newFollowUp);
export const updateFollowUp = (id, updatedNote) =>
    API.patch(`/notes/${id}`, updatedNote);

export const createPayment = (data2) => API.post("/payments", data2);

export const getCategoryCount = () => API.get("/category");

export const getStatusCount = () => API.get("/status");

export const updateFilePost = (id, updatedPost) =>
    API.patch(`/files/${id}`, updatedPost);

export const getRetainedFiles = () => API.get(`/retainedFile`);
export const getRetainedStatus = (email) => API.get(`/retainedFile/${email}`);
export const createRetainedAgreement = (newPost) =>
    API.post("/retainedFile", newPost);
export const updateRetainedAgreement = (id, updatedPost) =>
    API.patch(`/retainedFile/${id}`, updatedPost);

export const followUpCustomer = (userEmail) => API.post("/contact", userEmail);
export const followUpCustomerWithMessage = (customerQuery) =>
    API.post("/contact/userMessage", customerQuery);

export const forumList = () => API.get("/forums");
export const forumTopicList = (topic) => API.get(`/forums/${topic}`);
export const createForumPost = (newForum) => API.post("/forums", newForum);
export const updateForumTopic = (id, updatedForum) =>
    API.patch(`/forums/${id}`, updatedForum);
