import { Button, Form, Radio } from "antd";
import { useState } from "react";
import { successMsg } from "src/common";
import "./Eligibility.scss";
const layout = {
    wrapperCol: {
        span: 16,
    },
};
export default function ExtendSpousalPREligibility({ ...props }) {
    const [form] = Form.useForm();

    const [formDisable, setFormDisable] = useState(null);
    const onFinish = (values) => {
        props.postData.basicInfoClient = values;
        props.sendDataToParent(props.postData);
        setFormDisable(true);
    };

    const onReset = () => {
        form.resetFields();
    };
    const [value, setValue] = useState("");
    const onLocationChange = (e) => {
        setValue(e.target.value);
    };
    return (
        <Form
            {...layout}
            form={form}
            name="control-hooks"
            className="basicInfoClient-form"
            onFinish={onFinish}
        >
            <Form.Item name="location" label="Where are you currently located?">
                <Radio.Group
                    onChange={onLocationChange}
                    value={value}
                    disabled={formDisable}
                >
                    <Radio value="Inside Canada"> Inside Canada</Radio>
                    <Radio value="Outside Canada">Outside Canada </Radio>
                </Radio.Group>
            </Form.Item>
            <Form.Item
                label="Is your spouse or common-law partner Canadian citizen or permanent resident?"
                name="status"
            >
                <Radio.Group disabled={formDisable}>
                    <Radio value="Yes">Yes</Radio>
                    <Radio value="No">No</Radio>
                </Radio.Group>
            </Form.Item>
            <Form.Item className="submitBtn" label="Submit">
                <Button type="primary" htmlType="submit" disabled={formDisable}>
                    Confirm
                </Button>
                <Button
                    htmlType="button"
                    onClick={onReset}
                    disabled={formDisable}
                >
                    Reset
                </Button>
            </Form.Item>
            {formDisable &&
                successMsg(
                    "Continue Next Step",
                    "Thank you for providing your inputs. You may proceed Next to read our terms and agreements to retain us for your application processing!",
                    { borderRadius: "10px" }
                )}
        </Form>
    );
}
