import { Col, Row } from "antd";
import { Link } from "react-router-dom";
import { AppButton } from "src/common";
import { ROUTE_URL } from "src/common/values";
import { PAGE_SECTION } from "src/constants/pageSection";

export default function Stats() {
    return (
        <Row className="cover-row padTop">
            <Col xs={24} sm={24} md={12} lg={14} xl={14}>
                <img
                    className="stats-cover"
                    src="https://res.cloudinary.com/asset-aivc/image/upload/v1659566439/stats-illustration_fy4hrq.svg"
                    alt="Our Track Record"
                ></img>
            </Col>
            <Col xs={24} sm={24} md={12} lg={10} xl={10}>
                <div className="stats-content">
                    <h5 className="highlight-stats">OUR TRACK RECORD</h5>
                    <h1 className="header-text">
                        Offering the best services to our clients since
                        <span className="highlightText">&nbsp; 2017</span>
                    </h1>
                    <p className="description-stats">
                        We at ExtendTheVisa only focus on CANADA Immigration
                        which is why we are different from others who are
                        dealing with multiple country pathway options. Our
                        Success rate of visa approval is high due to our
                        experienced professional approach to make your journey
                        hassle-free.
                    </p>
                    <div className="stats-numbers">
                        {PAGE_SECTION.MAIN.STATS.records.map((item, index) => (
                            <div key={index}>
                                <div className="stats-count">{item.count}</div>
                                <div className="stats-title"> {item.title}</div>
                            </div>
                        ))}
                    </div>
                    <Link to={`/${ROUTE_URL.AboutUs}`}>
                        <AppButton
                            title="
            Learn more"
                            css="cover-btn"
                            type="primary"
                        />
                    </Link>
                </div>
            </Col>
        </Row>
    );
}
