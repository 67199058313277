export const newStudyDocsList = [
    {
        title: "workDoc",
        label: "Evidence of Work Requirement in Study",
        supportFile: "Supported File Type: *.pdf max 4MB",
        acceptedFileType: ["application/pdf"],
    },
    {
        title: "tuitionProof",
        label: "Proof of first year tuition payment",
        supportFile: "Supported File Type: *.pdf max 4MB",
        acceptedFileType: ["application/pdf"],
    },
    {
        title: "ielts",
        label: "Proof of IELTS language test results",
        supportFile: "Supported File Type: *.pdf max 4MB",
        acceptedFileType: ["application/pdf"],
    },
    {
        title: "GIC",
        label: "Proof of Guaranteed Investment Certificate (GIC)",
        supportFile: "Supported File Type: *.pdf max 4MB",
        acceptedFileType: ["application/pdf"],
    },
    {
        title: "LOA",
        label: "Letter of Acceptance",
        supportFile: "Supported File Type: *.pdf max 4MB",
        acceptedFileType: ["application/pdf"],
    },
    {
        title: "medicals",
        label: "Proof of upfront medical exam",
        supportFile: "Supported File Type: *.pdf max 4MB",
        acceptedFileType: ["application/pdf"],
    },
    {
        title: "scholarship",
        label: "Any scholarship from the Govt., if applicable",
        supportFile: "Supported File Type: *.pdf max 4MB",
        acceptedFileType: ["application/pdf"],
    },
    {
        title: "employmentProof",
        label: "Proof of employment, if applicable",
        supportFile: "Supported File Type: *.pdf max 4MB",
        acceptedFileType: ["application/pdf"],
    },
];
export const extendStudyDocsList = [
    {
        title: "currentStudy",
        label: "Current Study Permit ",
        supportFile: "Supported File Type: *.pdf max 4MB",
        acceptedFileType: ["application/pdf"],
    },
    {
        title: "bank",
        label: "Bank Statement ",
        supportFile: "Supported File Type: *.pdf max 4MB",
        acceptedFileType: ["application/pdf"],
    },
    {
        title: "refusal",
        label: "Refusal Letter, if any ",
        supportFile: "Supported File Type: *.pdf max 4MB",
        acceptedFileType: ["application/pdf"],
    },
    {
        title: "marriage",
        label: "Marriage Certificate, if applicable ",
        supportFile: "Supported File Type: *.pdf max 4MB",
        acceptedFileType: ["application/pdf"],
    },
    {
        title: "extension",
        label: "Study Permit Extension Letter",
        supportFile: "Supported File Type: *.pdf max 4MB",
        acceptedFileType: ["application/pdf"],
    },
    {
        title: "enrollment",
        label: "Enrollment Letter for upcoming semester",
        supportFile: "Supported File Type: *.pdf max 4MB",
        acceptedFileType: ["application/pdf"],
    },
    {
        title: "transcript",
        label: "Official Transcript ",
        supportFile: "Supported File Type: *.pdf max 4MB",
        acceptedFileType: ["application/pdf"],
    },
    {
        title: "employment",
        label: "Current Employment letter  ",
        supportFile: "Supported File Type: *.pdf max 4MB",
        acceptedFileType: ["application/pdf"],
    },
    {
        title: "paystub",
        label: "3 Recent Paystubs  ",
        supportFile: "Supported File Type: *.pdf max 4MB",
        acceptedFileType: ["application/pdf"],
    },
];
